module.exports = [{
      plugin: require('../plugins/gatsby-plugin-google-tagmanager-apostore/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5ZLSGWB","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/components/Layout/index.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
