import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { scale } from '@/styles/utils';
import { pLarge } from '@/styles/typography';

export default styled(
  ({ hasError, isEmpty, isActive, isSubmitting, ...rest }) => (
    <Field {...rest} />
  )
)`
  border: 0;
  border-bottom: 1px solid
    ${props =>
      props.hasError
        ? `${props.theme.color.red}`
        : `${props.theme.color.grayMedium}`};
  width: 100%;
  height: ${scale(1.75)};
  border-radius: 0;
  -webkit-appearance: none;
  background: transparent;
  ${pLarge}
  color: ${props =>
    props.isSubmitting
      ? props.theme.color.grayDark
      : props.theme.color.grayDarker};

  :focus {
    outline: none;
    ${props =>
      !props.hasError &&
      `
      border-color: ${props.theme.color.grayDarker};
    `};
  }

  ::placeholder {
    color: ${props => props.theme.color.grayDark};
    opacity: 1;
  }

  :invalid {
    box-shadow: none;
  }

  :disabled {
    color: ${props => props.theme.color.grayDark};
    -webkit-text-fill-color: ${props => props.theme.color.grayDark};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${props =>
      props.isSubmitting
        ? props.theme.color.grayDark
        : props.theme.color.grayDarker};
  }
`;
