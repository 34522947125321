import React, { useContext } from 'react';
import { t } from '@lingui/macro';

import LocalizationContext from '@/context/LocalizationContext';

import Form from '@/components/Form/';

export default ({ hasMessageField, ...props }) => {
  const [, i18n] = useContext(LocalizationContext);
  const fields = [
    {
      name: 'name',
      label: i18n._(t('form.name.label')`Name`),
      errorMessageLabel: i18n._(t('form.name.errorMessageLabel')`Ihren Namen`),
    },
    {
      name: 'email',
      label: i18n._(t('form.email.label')`Email`),
      errorMessageLabel: i18n._(
        t('form.email.errorMessageLabel')`Ihre Email-Adresse`
      ),
      type: 'email',
      validation: {
        check: email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email),
        errorMessage: i18n._(
          t(
            'form.email.invalidEmailAddress'
          )`Bitte geben Sie eine gültige Email-Adresse an`
        ),
      },
    },
    {
      name: 'phoneNumber',
      label: i18n._(t('form.phoneNumber.label')`Telefonnummer`),
      errorMessageLabel: i18n._(
        t('form.phoneNumer.errorMessageLabel')`Ihre Telefonnummer`
      ),
      isOptional: true,
    },
    {
      name: 'country',
      label: i18n._(t('form.country.label')`Land`),
      errorMessageLabel: i18n._(t('form.country.errorMessageLabel')`ein Land`),
      width: 1 / 2,
    },
    {
      name: 'city',
      label: i18n._(t('form.city.label')`Ort`),
      errorMessageLabel: i18n._(t('form.city.errorMessageLabel')`einen Ort`),
      isOptional: true,
      width: 1 / 2,
    },
  ];

  if (hasMessageField) {
    fields.push({
      name: 'message',
      label: i18n._(t('form.message.label')`Nachricht`),
      errorMessageLabel: i18n._(
        t('form.message.errorMessageLabel')`eine Nachricht`
      ),
      isOptional: true,
      isTextArea: true,
    });
  }

  return <Form name="contactForm" fields={fields} {...props} />;
};
