import React from 'react';
import styled from 'styled-components';

import { media, scale } from '@/styles/utils';
import { h4, h5, p } from '@/styles/typography';

const RichText = styled.div.attrs(({ mt, mb }) => ({
  mt: mt ? mt.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  mb: mb ? mb.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
}))`
  margin-top: ${props => props.mt[0]};
  margin-bottom: ${props => props.mb[0]};

  ${media.sm`
    margin-top: ${props => props.mt[1]};
    margin-bottom: ${props => props.mb[1]};
  `}

  ${media.md`
    margin-top: ${props => props.mt[2]};
    margin-bottom: ${props => props.mb[2]};
    text-align: ${props => props.alignment};
  `}

  ${media.lg`
    margin-top: ${props => props.mt[3]};
    margin-bottom: ${props => props.mb[3]};
  `}

  h3 {
    ${h4}
    margin-top: ${scale(2.5)};
    margin-bottom: ${scale(0.75)};
  }

  h4 {
    ${h5}
    margin-top: ${scale(1.75)};
    margin-bottom: ${scale(0.75)};
  }

  p,
  ol,
  ul {
    ${p}
    margin-bottom: ${scale(0.75)};
    hyphens: auto;
  }

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }

  ol, ul {
    padding-left: ${scale(2)};

    li {
      padding-left: ${scale(0.5)};
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.color.base};
    transition: all 0.1s ease-out;

    :hover, :focus {
      color: ${props => props.theme.color.red};
      border-color: ${props => props.theme.color.red};
    }
  }
`;

export default ({ htmlContent, ...props }) => (
  <RichText dangerouslySetInnerHTML={{ __html: htmlContent }} {...props} />
);
