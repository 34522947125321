/*
read: https://medium.com/@david.gilbertson/icons-as-react-components-de3e33cb8792
use: https://jakearchibald.github.io/svgomg/
*/

import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  box-sizing: content-box;
  fill: currentColor;
`;

const icons = {
  default: {
    arrowRight:
      '<path d="M16.043 12.75H5v-1.5h11.043L13 8.306l1.09-1.056L19 12l-4.91 4.75L13 15.694l3.043-2.944z" fill-rule="nonzero" />',
    caretUp:
      '<path d="M7.199 14.785a.681.681 0 0 1-.993 0 .757.757 0 0 1 0-1.037l5.297-5.533a.681.681 0 0 1 .994 0l5.297 5.533a.757.757 0 0 1 0 1.037.681.681 0 0 1-.993 0L12 9.771l-4.801 5.014z" fill-rule="nonzero"/>',
    caretDown:
      '<path d="M7.199 9.215a.681.681 0 0 0-.993 0 .757.757 0 0 0 0 1.037l5.297 5.533a.681.681 0 0 0 .994 0l5.297-5.533a.757.757 0 0 0 0-1.037.681.681 0 0 0-.993 0L12 14.229 7.199 9.215z" fill-rule="nonzero" />',
    caretLeft:
      '<path d="M14.785 7.199a.681.681 0 0 0 0-.993.757.757 0 0 0-1.037 0l-5.533 5.297a.681.681 0 0 0 0 .994l5.533 5.297a.757.757 0 0 0 1.037 0 .681.681 0 0 0 0-.993L9.771 12l5.014-4.801z" fill-rule="nonzero" />',
    caretRight:
      '<path d="M9.215 16.801a.681.681 0 0 0 0 .993.757.757 0 0 0 1.037 0l5.533-5.297a.681.681 0 0 0 0-.994l-5.533-5.297a.757.757 0 0 0-1.037 0 .681.681 0 0 0 0 .993L14.229 12l-5.014 4.801z" fill-rule="nonzero"/>',
    caretDropdown:
      '<path d="M12 13.108l3.718-3.878a.73.73 0 0 1 1.062 0 .808.808 0 0 1 0 1.108l-4.249 4.432a.73.73 0 0 1-1.062 0L7.22 10.338a.808.808 0 0 1 0-1.108.73.73 0 0 1 1.062 0L12 13.108z" fill-rule="nonzero" />',
    download:
      '<path d="M11.25 15.043V4h1.5v11.043L15.694 12l1.056 1.09L12 18l-4.75-4.91L8.306 12l2.944 3.043zM5 18h14v1.5H5V18z" fill-rule="nonzero" />',
    linkedin:
      '<path d="M19 19h-2.898v-4.547c0-1.085-.021-2.48-1.508-2.48-1.51 0-1.74 1.181-1.74 2.401V19H9.952V9.649h2.783v1.277h.04c.388-.734 1.334-1.51 2.746-1.51 2.936 0 3.478 1.936 3.478 4.455V19zM6.682 8.37C5.752 8.37 5 7.616 5 6.686S5.752 5 6.682 5a1.685 1.685 0 0 1 0 3.37zM5.23 19V9.649h2.903V19H5.23z" fill-rule="evenodd" />',
    xing:
      '<path d="M6.24 7.16h2.308c.354 0 .527.228.642.433l1.586 2.766-2.49 4.405c-.119.213-.285.446-.63.446H5.348a.34.34 0 0 1-.301-.157c-.061-.099-.064-.227 0-.354l2.452-4.327c.002-.005.002-.008 0-.013l-1.56-2.7c-.065-.13-.075-.256-.014-.355.059-.095.176-.144.315-.144zM15.934 4h2.332c.138 0 .248.052.306.147.061.099.06.229-.004.356l-5.11 9.034a.013.013 0 0 0 0 .015l3.254 5.944c.064.128.066.258.004.357-.058.095-.167.147-.306.147h-2.305c-.353 0-.53-.235-.645-.44l-3.278-6.015 5.135-9.106c.123-.222.273-.439.617-.439z" fill-rule="evenodd"/>',
    youtube:
      '<path d="M10.16 15.244V9.756l4.704 2.744-4.705 2.744zM20.623 8.03a2.291 2.291 0 0 0-1.592-1.642C17.63 6 12 6 12 6s-5.629 0-7.032.388A2.291 2.291 0 0 0 3.376 8.03C3 9.478 3 12.5 3 12.5s0 3.022.376 4.47c.207.8.817 1.428 1.592 1.642C6.37 19 12 19 12 19s5.629 0 7.032-.388a2.291 2.291 0 0 0 1.592-1.642C21 15.522 21 12.5 21 12.5s0-3.022-.376-4.47z" fill-rule="evenodd"/>',
    facebook:
      '<path d="m15.7 13 .4-2.9h-2.8V8.2c0-.8.4-1.6 1.6-1.6h1.3V4.2S15.1 4 14 4c-2.3 0-3.8 1.4-3.8 3.9v2.2H7.7V13h2.5v7h3.1v-7h2.4z"/>',
    instagram:
      '<path d="M12 7.9c-2.3 0-4.1 1.8-4.1 4.1s1.8 4.1 4.1 4.1 4.1-1.8 4.1-4.1-1.8-4.1-4.1-4.1zm0 6.8c-1.5 0-2.7-1.2-2.7-2.7s1.2-2.7 2.7-2.7 2.7 1.2 2.7 2.7-1.2 2.7-2.7 2.7zm5.2-7c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1c.6.1 1 .5 1 1zm2.7 1c-.1-1.3-.4-2.4-1.3-3.4-.9-.9-2.1-1.2-3.4-1.3-1.2 0-5.2 0-6.5.1-1.3 0-2.4.3-3.4 1.2S4.1 7.4 4.1 8.7C4 10 4 14 4.1 15.3c.1 1.3.4 2.4 1.3 3.4s2.1 1.2 3.4 1.3c1.3.1 5.3.1 6.6 0 1.3-.1 2.4-.4 3.4-1.3.9-.9 1.2-2.1 1.3-3.4-.1-1.3-.1-5.3-.2-6.6zm-1.7 8c-.3.7-.8 1.2-1.5 1.5-1.1.4-3.6.3-4.7.3s-3.7.1-4.7-.3c-.7-.2-1.3-.8-1.5-1.5-.4-1.1-.3-3.6-.3-4.7s-.1-3.7.3-4.7c.2-.7.8-1.3 1.5-1.5 1.1-.4 3.6-.3 4.7-.3s3.7-.1 4.7.3c.7.2 1.3.8 1.5 1.5.4 1.1.3 3.6.3 4.7s.2 3.7-.3 4.7z"/>',
    check:
      '<path d="M10.326 15.141l7.331-8.865a.753.753 0 0 1 1.071-.095.78.78 0 0 1 .094 1.087l-7.82 9.456a.753.753 0 0 1-1.07.095l-4.66-3.967a.78.78 0 0 1-.094-1.087.753.753 0 0 1 1.071-.095l4.077 3.471z"/>',
  },
  large: {
    caretLeft:
      '<path d="M24.761 30.508a.92.92 0 0 1 0 1.236.776.776 0 0 1-1.152 0L13.24 20.618a.92.92 0 0 1 0-1.236l10.37-11.126a.776.776 0 0 1 1.152 0 .92.92 0 0 1 0 1.236L14.967 20l9.794 10.508z" fill-rule="nonzero" />',
    caretRight:
      '<path d="M15.239 30.508L25.033 20 15.239 9.492a.92.92 0 0 1 0-1.236.776.776 0 0 1 1.152 0l10.37 11.126a.92.92 0 0 1 0 1.236l-10.37 11.126a.776.776 0 0 1-1.152 0 .92.92 0 0 1 0-1.236z" fill-rule="nonzero" />',
    play: '<path fill="#F8F9FB" fill-rule="nonzero" d="M12 4l24 16-24 16z" />',
    hamburger:
      '<path d="M7 11h26a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2zm0 8h26a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2zm0 8h26a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2z" fill="#F8F9FB" fill-rule="evenodd" />',
    close:
      '<path d="M21.388 20l8.325 8.325a.981.981 0 1 1-1.388 1.388L20 21.388l-8.325 8.325a.981.981 0 1 1-1.388-1.388L18.612 20l-8.325-8.325a.981.981 0 1 1 1.388-1.388L20 18.612l8.325-8.325a.981.981 0 1 1 1.388 1.388L21.388 20z" fill="#F8F9FB" fill-rule="evenodd" />',
  },
};

const sizes = {
  default: 24,
  large: 40,
};

export default ({ type, size = 'default', width, ...rest }) => (
  <Svg
    viewBox={`0 0 ${sizes[size]} ${sizes[size]}`}
    width={width || sizes[size]}
    height={width || sizes[size]}
    {...rest}
    dangerouslySetInnerHTML={{ __html: icons[size][type] }} // eslint-disable-line react/no-danger
  />
);
