import styled from 'styled-components';

import GridItem from './Item';
import GridContainer from './Container';

import { scale, media } from '@/styles/utils';

const Grid = styled.div.attrs(({ alignItems, justifyContent, theme }) => ({
  alignItems: alignItems || 'flex-start',
  justifyContent: justifyContent || 'flex-start',
  halfGutter: theme.grid.gutter.map(g => (g ? scale(g / 2) : 'auto')),
}))`
  display: flex;
  flex-wrap: wrap;
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  position: relative;
  margin-left: -${props => props.halfGutter[0]};
  margin-right: -${props => props.halfGutter[0]};

  ${media.sm`
    margin-left: -${props => props.halfGutter[1]};
    margin-right: -${props => props.halfGutter[1]};
  `}

  ${media.md`
    margin-left: -${props => props.halfGutter[2]};
    margin-right: -${props => props.halfGutter[2]};
  `}

  ${media.lg`
    margin-left: -${props => props.halfGutter[3]};
    margin-right: -${props => props.halfGutter[3]};
  `}
`;

Grid.Item = GridItem;
Grid.Container = GridContainer;

export default Grid;
