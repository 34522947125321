import styled from 'styled-components';

import { media, scale } from '@/styles/utils';

export default styled.section`
  position: relative;
  padding-top: ${scale(4)};
  padding-bottom: ${scale(2.5)};

  ${media.md`
    padding-top: ${scale(6)};
    padding-bottom: ${scale(3.5)};
  `}

  :first-child,
  :last-child {
    padding-bottom: ${scale(3.5)};

    ${media.md`
      padding-bottom: ${scale(5)};
    `}
  }

  :nth-child(2n) {
    background: ${props => props.theme.gradient.grayLighter};
  }
`;
