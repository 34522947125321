import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from '@/atoms/Icon';

import { scale } from '@/styles/utils';
import { buttonLabel } from '@/styles/typography';

const TextButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color.red};
  transition: color 0.1s ease-out;
  ${buttonLabel}

  :hover, :focus {
    color: ${props => props.theme.color.redDark};
  }

  :active {
    color: ${props => props.theme.color.redDarker};
  }
`;

const ButtonIcon = styled(Icon)`
  position: relative;
  margin-left: ${scale(0.25)};
  top: ${scale(0.05)};
`;

export default ({
  to = null,
  href = null,
  disabled = false,
  icon = 'arrowRight',
  children,
  ...props
}) => {
  const buttonProps = {
    disabled,
  };

  if (to) {
    if (!disabled) {
      buttonProps.to = to;
      buttonProps.as = Link;
    } else {
      buttonProps.as = 'div';
    }
  }

  if (href) {
    if (!disabled) {
      buttonProps.href = href;
      buttonProps.as = 'a';
    } else {
      buttonProps.as = 'div';
    }
  }

  return (
    <TextButton {...buttonProps} {...props}>
      {children}

      {icon && <ButtonIcon type={icon} />}
    </TextButton>
  );
};
