import React, { useContext } from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import Cookies from 'universal-cookie';

import LocalizationContext from '@/context/LocalizationContext';

import Dropdown from '@/atoms/Dropdown';
import SocialButton from '@/atoms/SocialButton';

import { scale, media } from '@/styles/utils';
import { localesData, getLangFromLocale } from '@/utils/localization';

const cookies = new Cookies();

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.color.grayDarker};
  padding-top: ${scale(2.5)};
  padding-bottom: ${scale(2)};

  ${media.sm`
    padding-top: ${scale(2)};
    padding-bottom: ${scale(1.5)};
  `}
`;

const SocialLinks = styled.ul`
  list-style: none;

  & > li {
    display: inline-block;
    padding-right: ${scale(0.5)};
  }
`;

export default props => {
  const [locale] = useContext(LocalizationContext);

  const handleLocaleChange = e => {
    const { baseUrl } = localesData[e.target.value];
    const lang = getLangFromLocale(e.target.value);

    // set cookie to prevent automatic netlify redirects
    cookies.set('nf_lang', lang, {
      path: '/',
      maxAge: 90 * 24 * 3600, // 90 days
    });

    // redirect to localized site
    navigate(baseUrl);
  };

  return (
    <Container {...props}>
      <SocialLinks>
        <li>
          <SocialButton
            link="https://de.linkedin.com/company/apostore-gmbh"
            type="linkedin"
          />
        </li>

        <li>
          <SocialButton
            link="https://www.xing.com/companies/apostoregmbh"
            type="xing"
          />
        </li>

        <li>
          <SocialButton
            link="https://www.youtube.com/channel/UCDoQhrCiK_1TbPYXMexdWKw"
            type="youtube"
          />
        </li>

        <li>
          <SocialButton
            link="https://www.instagram.com/apostore_de/"
            type="instagram"
          />
        </li>

        <li>
          <SocialButton
            link="https://www.facebook.com/ApostoreDE/"
            type="facebook"
          />
        </li>
      </SocialLinks>

      <Dropdown value={locale} onChange={handleLocaleChange}>
        {Object.keys(localesData).map(loc => (
          <option key={loc} value={loc}>
            {localesData[loc].label}
          </option>
        ))}
      </Dropdown>
    </Container>
  );
};
