import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import FormattedText from '@/atoms/FormattedText';
import Image from '@/atoms/Image';
import Button from '@/atoms/Button';
import EventsList from './EventsList';

import { getPath } from '@/utils/routes';
import { getSrcsetSizesFromGridWidths } from '@/utils/grid';
import { media, scale } from '@/styles/utils';

const Column = styled(Grid.Item)`
  ${media.md`
    padding-left: ${scale(2)};
    padding-right: ${scale(2)};
  `}

  ${media.lg`
    padding-left: ${scale(4)};
    padding-right: ${scale(4)};
  `}
`;

const MagazineImage = styled(Image)`
  margin-bottom: ${scale(2)};
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const width = [1, 1, 1 / 2];

export default ({
  headingEvents,
  introEvents,
  events,
  headingMagazine,
  introMagazine,
  imageMagazine,
  magazinePage,
  ...props
}) => (
  <Grid.Container mt={[1]} {...props}>
    <Grid>
      <Column w={width} mb={[3, 3, 0]}>
        <Heading h={3} as="h3" alignment={['left', 'left', 'center']} mb={[1]}>
          {headingEvents}
        </Heading>

        {introEvents && (
          <FormattedText
            t={0}
            alignment={['left', 'left', 'center']}
            textColor="grayDark"
            mb={[1, 1, 2.5]}
          >
            {introEvents}
          </FormattedText>
        )}

        {events.length > 0 && <EventsList events={events} />}
      </Column>

      <Column w={width} mb={[3, 3, 0]}>
        <Heading h={3} as="h3" alignment={['left', 'left', 'center']} mb={[1]}>
          {headingMagazine}
        </Heading>

        {introMagazine && (
          <FormattedText
            t={0}
            alignment={['left', 'left', 'center']}
            textColor="grayDark"
            mb={[1.5]}
          >
            {introMagazine}
          </FormattedText>
        )}

        {imageMagazine[0] && (
          <MagazineImage
            file={imageMagazine[0].filename}
            width={imageMagazine[0].width}
            height={imageMagazine[0].height}
            alt={imageMagazine[0].title}
            sizes={getSrcsetSizesFromGridWidths(width)}
          />
        )}

        {magazinePage[0] && (
          <ButtonWrapper>
            <Button to={getPath(magazinePage[0].uri)} appearance="secondary">
              <Trans id="eventsMagazine.button.order">Magazin bestellen</Trans>
            </Button>
          </ButtonWrapper>
        )}
      </Column>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleEventsMagazineData on CraftCMS_ModulesEventsMagazine {
    id
    headingEvents
    introEvents
    events {
      name
      location
      date
    }
    headingMagazine
    introMagazine
    imageMagazine {
      filename
      width
      height
      title
    }
    magazinePage {
      uri
    }
  }
`;
