import React, { createContext, useReducer } from 'react';

const defaultState = {
  isExpanded: false,
  isButtonVisible: true,
};

const ContactContext = createContext(false);

export const ContactProvider = props => {
  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case 'SET_EXPANDED':
        return {
          ...prevState,
          isExpanded: action.value,
        };
      case 'SET_BUTTON_VISIBILITY':
        return {
          ...prevState,
          isButtonVisible: action.value,
        };
      default:
        return prevState;
    }
  }, defaultState);

  return <ContactContext.Provider value={[state, dispatch]} {...props} />;
};

export default ContactContext;
