import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

const getRelationsComposite = relations => {
  const labels = relations.map(({ label }) => label);

  return (
    <>
      {labels.map((label, i) =>
        i === 0 ? (
          <strong key={label}>{label}</strong>
        ) : (
          <span key={label}>
            , <strong>{label}</strong>
          </span>
        )
      )}
    </>
  );
};

export default ({
  data: {
    automatsFieldData,
    extensionsFieldData,
    uri,
    thumbnail,
    title,
    abstract,
  },
  width,
  widthName,
  ...props
}) => {
  const automats = getRelationsComposite(automatsFieldData.selected);
  const extensions = getRelationsComposite(extensionsFieldData.selected);

  let footerContent = null;

  if (
    automatsFieldData.selected.length > 0 &&
    extensionsFieldData.selected.length > 0
  ) {
    footerContent = (
      <Trans id="card.solution.footer.combinations.automats+extensions">
        Realisierbar mit {automats} und {extensions}.
      </Trans>
    );
  } else if (automatsFieldData.selected.length > 0) {
    footerContent = (
      <Trans id="card.solution.footer.combinations.automats">
        Realisierbar mit {automats}.
      </Trans>
    );
  } else if (extensionsFieldData.selected.length > 0) {
    footerContent = (
      <Trans id="card.solution.footer.combinations.extensions">
        Realisierbar mit {extensions}.
      </Trans>
    );
  }

  const isHorizontal = widthName === 'w1_1';

  return (
    <Card
      uri={uri}
      layout={isHorizontal ? 'horizontal' : 'default'}
      thumbnail={isHorizontal && thumbnail && thumbnail[0]}
      thumbnailWidth={isHorizontal ? [1, 1, 0.5] : width}
      heading={title}
      headingSize="xlarge"
      spacing="large"
      text={abstract}
      footerText={footerContent}
      footerAlignBottom={!isHorizontal}
      hasButton
      buttonLabel={<Trans id="viewButton.label">Ansehen</Trans>}
      {...props}
    />
  );
};

export const query = graphql`
  fragment cardSolutionData on CraftCMS_PageSolution {
    title
    uri
    automatsFieldData: automats_FieldData {
      selected {
        label
      }
    }
    extensionsFieldData: extensions_FieldData {
      selected {
        label
      }
    }
    abstract
    cardWidth
    thumbnail {
      filename
      title
    }
  }
`;
