import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

const Header = styled.header`
  margin-bottom: ${scale(0.75)};

  ${props =>
    props.size === 'xlarge' &&
    `
    margin-top: ${scale(1)};
    margin-bottom: ${scale(1)};
  `}
`;

const hSize = {
  default: 5,
  large: 4,
  xlarge: 3,
};

const subhSize = {
  default: 0,
  small: -2,
};

export default ({
  label,
  link,
  heading,
  headingSize,
  subHeading,
  subHeadingSize,
  ...props
}) => (
  <Header size={headingSize} {...props}>
    {label && (
      <Heading h={6} as="h2" textColor="grayMedium" mb={[0.25]}>
        {label}
      </Heading>
    )}
    <Heading h={hSize[headingSize]} as="h1">
      {link ? (
        <Link rel="bookmark" to={link}>
          {heading}
        </Link>
      ) : (
        heading
      )}
    </Heading>

    {subHeading && (
      <Text
        t={subhSize[subHeadingSize]}
        as="h2"
        textColor="grayMedium"
        mt={subHeadingSize === 'small' ? [0.5] : [0]}
      >
        {subHeading}
      </Text>
    )}
  </Header>
);
