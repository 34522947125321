import Button from './Button';
import Cards from './Cards';
import CardsLatestBlog from './CardsLatestBlog';
import ContactInformation from './ContactInformation';
import ContactsList from './ContactsList';
import ContentCarousel from './ContentCarousel';
import DataSheet from './DataSheet';
import DownloadButton from './DownloadButton';
import DownloadsList from './DownloadsList';
import EntriesList from './EntriesList';
import Headline from './Headline';
import Hero from './Hero';
import Images from './Images';
import ImagesText from './ImagesText';
import Intro from './Intro';
import Quote from './Quote';
import RichText from './RichText';
import ProductIntro from './ProductIntro';
import ProductOverview from './ProductOverview';
import TextColumns from './TextColumns';
import Form from './Form';
import EventsMagazine from './EventsMagazine';
import Video from './Video';

const ContentModule = {
  Button,
  Cards,
  CardsLatestBlog,
  ContactInformation,
  ContactsList,
  ContentCarousel,
  DataSheet,
  DownloadButton,
  DownloadsList,
  EntriesList,
  Headline,
  Hero,
  Images,
  ImagesText,
  Intro,
  Quote,
  RichText,
  ProductIntro,
  ProductOverview,
  TextColumns,
  Form,
  EventsMagazine,
  Video,
};

export default ContentModule;
