import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

export default ({ data, width, ...props }) => {
  const automat = data.automat_FieldData.selected.label;
  return (
    <Card
      uri={data.uri}
      thumbnail={data.thumbnail && data.thumbnail[0]}
      thumbnailWidth={width}
      heading={data.title}
      subHeading={data.location}
      text={data.abstract}
      footerText={
        <Trans id="card.reference.usingSince">
          Setzt den <strong>{automat}</strong> seit {data.year} ein.
        </Trans>
      }
      {...props}
    />
  );
};

export const query = graphql`
  fragment cardReferenceData on CraftCMS_PageReference {
    title
    uri
    location
    automat_FieldData {
      selected {
        label
      }
    }
    year
    abstract
    thumbnail {
      filename
      title
    }
  }
`;
