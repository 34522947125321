import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import { getPath } from '@/utils/routes';
import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

import Thumbnail from './Thumbnail';
import Header from './Header';
import Footer from './Footer';
import ProductData from './ProductData';

const Card = styled(Grid.Item).attrs({ as: 'article' })`
  display: flex;
  flex-direction: column;
`;

const Description = styled(Text).attrs({ t: -1 })`
  flex-grow: 1;
`;

const cardWidth = [1, 1, 1 / 3, 1 / 3];

export default ({
  uri,
  thumbnail,
  title,
  shortDescription,
  capacity,
  dispensing,
  ...props
}) => {
  const link = uri && getPath(uri);

  return (
    <Card {...props} w={cardWidth} mb={[4.5, 4.5, 4.5, 2.5]}>
      {thumbnail && (
        <Thumbnail
          link={link}
          srcsetSizes={getSrcsetSizesFromGridWidths(cardWidth)}
          {...thumbnail[0]}
        />
      )}

      <Header link={link} heading={title} />

      {shortDescription && (
        <Description mb={[1, 1, 1.5]}>{shortDescription}</Description>
      )}

      <ProductData capacity={capacity} dispensing={dispensing} />

      <Footer link={link} />
    </Card>
  );
};
