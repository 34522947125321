import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

export default ({
  data: { automatsFieldData, uri, thumbnail, title, abstract },
  width,
  ...props
}) => {
  const automats = automatsFieldData.selected.map(({ label }) => label);

  const lastAutomat = automats.slice(-1);
  const firstAutomats = automats.slice(0, -1);

  const firstAutomatsComposite = (
    <>
      {firstAutomats.map((automat, i) =>
        i === 0 ? (
          <strong key={automat}>{automat}</strong>
        ) : (
          <span key={automat}>
            , <strong>{automat}</strong>
          </span>
        )
      )}
    </>
  );

  return (
    <Card
      uri={uri}
      thumbnail={thumbnail && thumbnail[0]}
      thumbnailWidth={width}
      heading={title}
      headingSize="large"
      subHeadingSize="small"
      subHeading={
        automats.length > 1 ? (
          <Trans id="card.productExtension.compatible.multiple">
            Kompatibel mit {firstAutomatsComposite}
            und <strong>{lastAutomat}</strong>.
          </Trans>
        ) : (
          <Trans id="card.productExtension.compatible.one">
            Kompatibel mit <strong>{lastAutomat}</strong>.
          </Trans>
        )
      }
      text={abstract}
      hasButton
      buttonLabel={<Trans id="viewButton.label">Ansehen</Trans>}
      {...props}
    />
  );
};

export const query = graphql`
  fragment cardProductExtensionData on CraftCMS_PageProductExtension {
    title
    abstract
    thumbnail {
      filename
      title
    }
    uri
    cardWidth
    automatsFieldData: automats_FieldData {
      selected {
        label
      }
    }
  }
`;
