import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTrail, animated } from 'react-spring';

import { mainNavigationItem } from '@/styles/typography';
import { media, scale } from '@/styles/utils';

const Nav = styled.nav`
  flex-grow: 1;
  margin-top: ${scale(1.5)};
  width: 100%;

  ${media.md`
    flex-grow: 0;
    margin-top: 0;
    width: auto;
  `}
`;

const NavList = styled.ul`
  padding: ${scale(1.5)} 0;
  list-style: none;

  ${media.md`
    padding: 0;
  `}
`;

const NavListItem = styled(animated.li)`
  padding: ${scale(0.5)} 0;
  ${mainNavigationItem}

  ${media.md`
    display: inline-block;
    vertical-align: middle;
    padding: 0 ${scale(0.625)};
    opacity: 1 !important;
    transform: translateX(0) !important;
  `}

  ${media.lg`
    padding: 0 ${scale(0.875)};
  `}

  :first-child {
    padding-left: 0;
  }

  :last-child {
    padding-right: 0;
  }
`;

const Separator = styled(animated.li)`
  margin: ${scale(1)} 0;
  height: 1px;
  width: 75%;
  background: rgba(255, 255, 255, 0.2);

  ${media.md`
    display: inline-block;
    vertical-align: middle;
    margin: 0 ${scale(0.625)};
    width: 1px;
    height: ${scale(2.5)};
    opacity: 1 !important;
    transform: translateX(0) !important;
  `}

  ${media.lg`
    margin: 0 ${scale(1.25)};
    height: ${scale(3.5)};
  `}
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.color.grayLighter};
  text-decoration: none;
  opacity: 0.75;
  transition: opacity 0.15s ease-out;

  :hover,
  :focus,
  &.active {
    opacity: 1;
  }
`;

export default ({ navigationItems, isExpanded, ...props }) => {
  // add separators as items
  const navigationItemsWithSeparators = navigationItems.reduce((acc, cur) => {
    const items = [{ type: 'navItem', ...cur }];

    if (cur.hasSeparatorAfter) {
      items.push({ type: 'separator', id: `${cur.id}-separator` });
    }

    return acc.concat(items);
  }, []);

  const trail = useTrail(navigationItemsWithSeparators.length, {
    config: { mass: 1, tension: 260, friction: 26, clamp: true },
    reverse: !isExpanded,
    from: {
      opacity: 0,
      transform: 'translateX(-70px)',
    },
    to: {
      opacity: isExpanded ? 1 : 0,
      transform: isExpanded ? 'translateX(0)' : 'translateX(-70px)',
    },
  });

  return (
    <Nav {...props}>
      <NavList>
        {trail.map((animationProps, i) => {
          const item = navigationItemsWithSeparators[i];

          if (item.type === 'navItem') {
            return (
              <NavListItem key={item.id} style={animationProps}>
                <NavLink
                  to={item.path}
                  activeClassName="active"
                  partiallyActive
                >
                  {item.label}
                </NavLink>
              </NavListItem>
            );
          }

          if (item.type === 'separator') {
            return <Separator key={item.id} style={animationProps} />;
          }

          return null;
        })}
      </NavList>
    </Nav>
  );
};
