import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import MainNavigation from './MainNavigation';
import Logo from './Logo';
import NavToggle from './NavToggle';

import { media, scale } from '@/styles/utils';

const MainBarWrapper = styled.div`
  position: relative;
  height: ${props => scale(props.theme.measure.header.mainBar[0])};

  ${media.md`
    height: ${props => scale(props.theme.measure.header.mainBar[1])};
  `}
`;

const MainBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: ${props =>
    props.isExpanded ? '100vh' : scale(props.theme.measure.header.mainBar[0])};
  background: ${props => props.theme.gradient.red};
  transition: height 0.4s ease-out;
  transition-delay: ${props => (props.isExpanded ? 0 : `0.1s`)};
  overflow-y: ${props => (props.isExpanded ? 'scroll' : 'hidden')};
  -webkit-overflow-scrolling: touch;

  ${media.md`
    height: ${props => scale(props.theme.measure.header.mainBar[1])};
  `}
`;

const MainBarContainer = styled(Grid.Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  ${media.md`
    flex-direction: row;
  `}
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  height: ${props =>
    props.isExpanded ? '100vh' : scale(props.theme.measure.header.mainBar[0])};

  ${media.md`
    width: auto;
    height: ${props => scale(props.theme.measure.header.mainBar[1])};
  `}
`;

export default ({ isExpanded, toggleExpanded, navigationItems, ...props }) => (
  <MainBarWrapper {...props}>
    <MainBar isExpanded={isExpanded}>
      <MainBarContainer>
        <TopWrapper>
          <Logo />

          <NavToggle isExpanded={isExpanded} onClick={toggleExpanded} />
        </TopWrapper>

        <MainNavigation
          navigationItems={navigationItems}
          isExpanded={isExpanded}
        />
      </MainBarContainer>
    </MainBar>
  </MainBarWrapper>
);
