import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useTransition, animated } from 'react-spring';
import ContactContext from '@/context/ContactContext';

import Grid from '@/atoms/Grid';
import OrderForm from '@/components/OrderForm';
import ContactForm from '@/components/ContactForm';
import Button from '@/atoms/Button';

import Intro from './Intro';

const ButtonContainer = styled(Grid.Item)`
  text-align: center;
`;

const AnimatedGridItem = styled(animated(Grid.Item))`
  color: 'red';
`;

export default ({
  hasMessageField,
  formType,
  introText,
  expandOnClick = false,
  expandButtonLabel,
  ...props
}) => {
  const [{ isExpanded }, dispatch] = useContext(ContactContext);

  const transitions = useTransition(isExpanded || !expandOnClick, null, {
    from: {
      opacity: 0,
    },
    enter: { opacity: 1 },
  });

  return (
    <Grid.Container {...props}>
      <Grid>
        {expandOnClick && !isExpanded && (
          <ButtonContainer w={[1]}>
            <Button
              onClick={() => dispatch({ type: 'SET_EXPANDED', value: true })}
            >
              {expandButtonLabel}
            </Button>
          </ButtonContainer>
        )}

        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <AnimatedGridItem
                key={key}
                style={expandOnClick ? props : {}}
                w={[1, 9 / 12, 8 / 12, 6 / 12]}
                o={[0, 0, 2 / 12, 3 / 12]}
              >
                <Intro
                  showContactInfo={formType === 'contactForm'}
                  introText={introText}
                />
                {formType === 'contactForm' ? (
                  <ContactForm hasMessageField={hasMessageField} />
                ) : (
                  <OrderForm hasMessageField={hasMessageField} />
                )}
              </AnimatedGridItem>
            )
        )}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment contentModuleFormData on CraftCMS_ModulesForm {
    id
    hasMessageField
    formType
    introText
    expandOnClick
    expandButtonLabel
  }
`;
