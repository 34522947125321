import { css } from 'styled-components';

import { rem, media } from '@/styles/utils';

const reset = css`
  margin: 0;
  font-weight: normal;
  text-decoration: none;
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(34)};
  font-weight: 300;
  letter-spacing: -0.01em;
  color: ${props => props.theme.color.grayDarker};

  ${media.md`
    font-size: ${rem(56)};
    line-height: ${rem(64)};
  `}
`;

export const h1Hero = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(34)};
  font-weight: 300;
  letter-spacing: -0.01em;
  color: ${props => props.theme.color.grayDarker};

  ${media.md`
    font-size: ${rem(44)};
    line-height: ${rem(50)};
  `}

  ${media.lg`
    font-size: ${rem(56)};
    line-height: ${rem(64)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(32)};
  font-weight: 300;
  letter-spacing: -0.01em;
  color: ${props => props.theme.color.grayDarker};

  ${media.md`
    font-size: ${rem(40)};
    line-height: ${rem(46)};
  `}
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(26)};
  line-height: ${rem(28)};
  font-weight: 300;
  letter-spacing: -0.01em;
  color: ${props => props.theme.color.grayDarker};

  ${media.md`
    font-size: ${rem(32)};
    line-height: ${rem(40)};
  `}
`;

export const h4 = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(28)};
  letter-spacing: -0.005em;
  color: ${props => props.theme.color.grayDarker};

  ${media.md`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `}
`;

export const h5 = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(28)};
  letter-spacing: -0.005em;
  color: ${props => props.theme.color.grayDarker};
`;

export const h6Large = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.theme.color.grayMedium};

  ${media.md`
    font-size: ${rem(18)};
  `}
`;

export const h6 = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(24)};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${props => props.theme.color.grayMedium};
`;

export const p = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  letter-spacing: -0.005em;

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(26)};
  `}
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  letter-spacing: -0.005em;
`;

export const pxSmall = css`
  ${reset}
  font-size: ${rem(13)};
  line-height: ${rem(18)};
  letter-spacing: -0.005em;
`;

export const pxxSmall = css`
  ${reset}
  font-size: ${rem(11)};
  line-height: ${rem(16)};
  letter-spacing: -0.005em;
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(18)};
  line-height: ${rem(24)};
  letter-spacing: -0.005em;

  ${media.md`
    font-size: ${rem(20)};
    line-height: ${rem(28)};
  `}
`;

export const buttonLabel = css`
  ${reset}
  font-size: ${rem(15)};
  line-height: ${rem(22)};
  font-weight: 500;
  letter-spacing: -0.003em;
`;

export const buttonLabelSmall = css`
  ${reset}
  font-size: ${rem(13)};
  line-height: ${rem(18)};
  font-weight: 500;
  letter-spacing: -0.003em;
`;

export const mainNavigationItem = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(36)};
  font-weight: 300;
  letter-spacing: -0.01em;

  ${media.md`
    font-size: ${rem(15)};
    line-height: ${rem(22)};
    font-weight: normal;
    letter-spacing: -0.005em;
  `}

  ${media.lg`
    font-size: ${rem(18)};
    line-height: ${rem(26)};
  `}
`;
