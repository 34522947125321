import React from 'react';
import styled from 'styled-components';

import useScrollToSection from '@/hooks/useScrollToSection';

import Text from '@/atoms/Text';

import { media, scale } from '@/styles/utils';

const Nav = styled.nav`
  flex: 1 0;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;

  ${media.lg`
    justify-content: center;
  `}
`;

const NavListItem = styled.li`
  padding: 0 ${scale(0.75)};
  flex-shrink: 0;

  :first-child {
    padding-left: 0;
  }

  :last-child {
    /* compensate for missing margin because of overflow */
    padding-right: ${props => scale(props.theme.grid.outerMargin[0])};

    ${media.md`
      padding-right: 0;
    `}
  }
`;

const NavLink = styled(Text).attrs({ t: -1, as: 'a' })`
  color: ${props =>
    props.isActive
      ? props.theme.color.grayDarker
      : props.theme.color.grayMedium};
  transition: color 0.15s ease-out;

  :hover,
  :focus {
    ${props =>
      !props.isActive &&
      `
      color: ${props.theme.color.grayDark};
    `}
  }
`;

export default ({ navigationItems, activeItem, ...props }) => {
  const [scrollTo] = useScrollToSection();

  const handleClick = (e, ref) => {
    e.preventDefault();

    // first item should always scroll to top
    if (ref === navigationItems[0].ref) {
      scrollTo(0);
    } else {
      scrollTo(ref.current);
    }

    return false;
  };

  return (
    <Nav {...props}>
      <NavList>
        {navigationItems.map(({ label, id, ref, itemRef }) => (
          <NavListItem key={id} ref={itemRef}>
            <NavLink
              href={`#${id}`}
              onClick={e => handleClick(e, ref)}
              isActive={id === activeItem}
            >
              {label}
            </NavLink>
          </NavListItem>
        ))}
      </NavList>
    </Nav>
  );
};
