import React from 'react';
import styled from 'styled-components';

import Button from '@/atoms/Button';

import { media, scale } from '@/styles/utils';

const Wrapper = styled.div`
  z-index: 50;
  position: fixed;
  left: 0;
  bottom: ${scale(1.5)};
  display: flex;
  justify-content: center;
  width: 100%;
  pointer-events: none;

  ${media.md`
    display: none;
  `}
`;

const ButtonMobile = styled(Button)`
  pointer-events: all;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-out;

  ${props =>
    !props.isVisible &&
    `
    opacity: 0;
    pointer-events: none;
  `}
`;

export default ({ label, onClick, isVisible, ...props }) => (
  <Wrapper>
    <ButtonMobile
      size="small"
      appearance="primary"
      onClick={onClick}
      isVisible={isVisible}
      {...props}
    >
      {label}
    </ButtonMobile>
  </Wrapper>
);
