import React, { createContext } from 'react';
import { I18n } from '@lingui/react';

import { getLocaleFromLang } from '@/utils/localization';

const LocalizationContext = createContext();

export const LocalizationProvider = props => (
  <I18n>
    {({ i18n }) => (
      <LocalizationContext.Provider
        value={[
          // eslint-disable-next-line no-underscore-dangle
          getLocaleFromLang(i18n._language),
          i18n,
        ]}
        {...props}
      />
    )}
  </I18n>
);

export default LocalizationContext;
