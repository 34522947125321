import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';

import { scale } from '@/styles/utils';

const Button = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: ${scale(2)};
  height: ${scale(2)};
  color: ${props => props.theme.color.grayDarker};
  background: ${props => props.theme.color.grayLighter};
  transition: all 0.1s ease-out;

  :hover,
  :focus {
    background: ${props => props.theme.color.grayMediumLight};
  }

  :active {
    background: ${props => props.theme.color.grayMedium};
  }
`;

export default ({ link, type, ...props }) => (
  <Button href={link} target="_blank" rel="noopener noreferrer" {...props}>
    <Icon type={type} />
  </Button>
);
