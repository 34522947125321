import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { media, scale } from '@/styles/utils';

const ContactText = styled(Text)`
  ${media.md`
    padding: 0 ${scale(4)};
  `}
`;

const PhoneHeading = styled(Heading)`
  color: ${props => props.theme.color.red};
`;

const ContactDetails = styled.div`
  margin: 0 0 ${scale(2.5)};
  border-bottom: 1px solid ${props => props.theme.color.grayLight};
  padding: 0 0 ${scale(2.25)};

  ${media.md`
    padding-bottom: ${scale(2.75)};
  `}
`;

export default ({ introText, showContactInfo = false, ...props }) => (
  <>
    <ContactDetails {...props}>
      {introText && (
        <Text
          t={0}
          alignment={['left', 'left', 'center']}
          textColor="grayDark"
          mb={[2]}
        >
          {introText}
        </Text>
      )}

      {showContactInfo && (
        <>
          <Text
            t={0}
            as="h4"
            alignment={['left', 'left', 'center']}
            textColor="grayDarker"
            mb={[0.5, 0.5, 0.25]}
          >
            <Trans id="form.intro.phoneNumber.heading">Kundenhotline</Trans>
          </Text>

          <PhoneHeading
            h={4}
            as="p"
            alignment={['left', 'left', 'center']}
            mb={[0.5]}
          >
            <Trans id="form.intro.phoneNumber.value">+49 209 94117 0</Trans>
          </PhoneHeading>

          <Heading h={6} as="p" alignment={['left', 'left', 'center']}>
            <Trans id="form.intro.openingHours">Mo–Fr 8–18 Uhr</Trans>
          </Heading>
        </>
      )}
    </ContactDetails>

    {showContactInfo && (
      <ContactText
        alignment={['left', 'left', 'center']}
        textColor="grayDarker"
        mb={[1]}
      >
        <Trans id="form.intro.leaveContactDetails">
          Hinterlassen Sie Ihre Kontaktdaten und wir melden uns innerhalb von 24
          Stunden bei Ihnen.
        </Trans>
      </ContactText>
    )}
  </>
);
