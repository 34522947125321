import React, { useState, useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Headroom from 'react-headroom';

import useLockBodyScroll from '@/hooks/useLockBodyScroll';
import useScrollToSection from '@/hooks/useScrollToSection';
import LocalizationContext from '@/context/LocalizationContext';
import ContactContext from '@/context/ContactContext';
import { getPath } from '@/utils/routes';

import MainBar from './MainBar';
import SecondaryBar from './SecondaryBar';
import ContactButtonMobile from './ContactButtonMobile';

const Header = styled.header`
  position: relative;
`;

export default ({
  showSubnavigation = false,
  showContactButton = false,
  contactButtonLabel = null,
  contactSectionRef = null,
  subnavigationItems = null,
  activeSubnavigationItem = null,
  ...props
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const [scrollTo] = useScrollToSection();
  const [locale] = useContext(LocalizationContext);
  const [
    { isButtonVisible: isContactButtonVisible },
    contactDispatch,
  ] = useContext(ContactContext);

  useLockBodyScroll(isExpanded);

  // ideally we would use our util-functions, but gatsby doesn't support interpolation in static queries atm
  const mainNavigationData = useStaticQuery(graphql`
    {
      craftcms {
        de: globals(site: "de") {
          ...mainNavigationData
        }
        dk: globals(site: "dk") {
          ...mainNavigationData
        }
        en: globals(site: "en") {
          ...mainNavigationData
        }
        it: globals(site: "it") {
          ...mainNavigationData
        }
        es: globals(site: "es") {
          ...mainNavigationData
        }
        pt: globals(site: "pt") {
          ...mainNavigationData
        }
        hu: globals(site: "hu") {
          ...mainNavigationData
        }
        ru: globals(site: "ru") {
          ...mainNavigationData
        }
      }
    }
  `);

  const mainNavigationItems = mainNavigationData.craftcms[
    locale
  ].mainNavigation.navigationList.map(item => ({
    id: item.id,
    label: item.label,
    path: item.target[0] && getPath(item.target[0].uri),
    hasSeparatorAfter: item.hasSeparatorAfter,
  }));

  const handleContactButtonClick = () => {
    contactDispatch({ type: 'SET_EXPANDED', value: true });
    contactSectionRef && scrollTo(contactSectionRef.current);
  };

  return (
    <>
      <Headroom style={{ zIndex: 100 }} {...props}>
        <Header>
          <MainBar
            isExpanded={isExpanded}
            toggleExpanded={() => setExpanded(!isExpanded)}
            navigationItems={mainNavigationItems}
          />

          {showSubnavigation && (
            <SecondaryBar
              showContactButton={showContactButton}
              contactButtonLabel={contactButtonLabel}
              onContactButtonClick={handleContactButtonClick}
              navigationItems={subnavigationItems}
              activeNavigationItem={activeSubnavigationItem}
            />
          )}
        </Header>
      </Headroom>

      {showContactButton && (
        <ContactButtonMobile
          onClick={handleContactButtonClick}
          label={contactButtonLabel}
          isVisible={isContactButtonVisible}
        />
      )}
    </>
  );
};

export const query = graphql`
  fragment mainNavigationData on CraftCMS_GlobalsSet {
    mainNavigation {
      navigationList {
        id
        label
        target {
          uri
        }
        hasSeparatorAfter
      }
    }
  }
`;
