import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

const EventEntry = styled.div`
  border: 1px solid ${props => props.theme.color.grayLight};
  border-width: 1px 0 0;
  padding: ${scale(1.5)} 0;

  :last-child {
    border-width: 1px 0;
  }
`;

export default ({ events }) => (
  <>
    <Heading h={6} as="h4" alignment={['left', 'left', 'center']} mb={[0.5]}>
      <Trans id="eventsList.upcoming">Bevorstehende Termine</Trans>
    </Heading>

    {events.map(event => (
      <EventEntry key={event.name}>
        <Heading h={5} as="h5" alignment={['left', 'left', 'center']}>
          {[event.name, event.location].join(', ')}
        </Heading>

        <Text alignment={['left', 'left', 'center']} textColor="grayDark">
          {event.date}
        </Text>
      </EventEntry>
    ))}
  </>
);
