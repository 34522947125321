import React from 'react';
import { graphql } from 'gatsby';

import Gallery from '@/components/Gallery';
import Grid from '@/atoms/Grid';

const sizeProps = {
  default: {
    w: [1, 1, 8 / 12],
    o: [0, 0, 2 / 12],
  },
  wide: {
    w: [1],
    o: [0],
  },
};

export default ({ images, size = 'default', ...props }) => (
  <Grid.Container mb={[1.5, 1.5, 2.5]} {...props}>
    <Grid alignItems="center">
      <Grid.Item {...sizeProps[size]}>
        {images.length !== 0 && (
          <Gallery images={images} sizes={sizeProps[size].w} />
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleImagesData on CraftCMS_ModulesImages {
    id
    size
    images {
      ... on CraftCMS_ImagesVolume {
        filename
        width
        height
        title
        caption
      }
    }
  }
`;
