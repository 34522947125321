import React from 'react';
import styled from 'styled-components';

import { media, rem, scale } from '@/styles/utils';
import { buttonLabelSmall } from '@/styles/typography';
import Icon from './Icon';

const Dropdown = styled.div`
  position: relative;

  select {
    ${buttonLabelSmall};
    appearance: none;
    display: inline-block;
    border: 0;
    padding-left: ${scale(1)};
    padding-right: ${scale(2)};
    height: ${scale(2)};
    border-radius: ${scale(1)};
    color: ${props => props.theme.color.grayLighter};
    background: ${props => props.theme.color.grayDarker};
    cursor: pointer;
    transition: all 0.1s ease-out;
    outline: none;

    ${media.belowmd`
      font-size: ${rem(16)};
    `}

    :hover,
    :focus,
    :active {
      background: ${props => props.theme.color.grayDarker10};
    }

    ::-ms-expand {
      display: none;
    }
  }
`;

const SelectIcon = styled(Icon).attrs({ type: 'caretDropdown' })`
  position: absolute;
  top: ${scale(0.25)};
  right: ${scale(0.5)};
  pointer-events: none;
`;

export default ({ children, onChange, value, ...props }) => (
  <Dropdown {...props}>
    <select onChange={onChange} value={value}>
      {children}
    </select>

    <SelectIcon />
  </Dropdown>
);
