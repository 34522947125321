import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Image from '@/atoms/Image';

const ImageLink = styled(Link)`
  flex: 0 0 auto;
  display: block;
  outline: none;
`;

const Thumbnail = styled(Image)`
  flex: 0 0 auto;
`;

export default ({ image, link, srcsetSizes = '100vw', ...props }) => {
  const imageProps = {
    file: image,
    sizes: srcsetSizes,
    aspectRatio: [16, 9],
  };

  return link ? (
    <ImageLink rel="bookmark" tabIndex="-1" to={link} {...props}>
      <Thumbnail {...imageProps} />
    </ImageLink>
  ) : (
    <Thumbnail {...imageProps} {...props} />
  );
};
