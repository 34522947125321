import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import LocalizationContext from '@/context/LocalizationContext';
import { getPath } from '@/utils/routes';

import Grid from '@/atoms/Grid';
import Contact from './Contact';
import Navigation from './Navigation';
import Secondary from './Secondary';
import Legal from './Legal';

import { scale, media } from '@/styles/utils';

const Footer = styled.footer`
  padding-top: ${scale(2.5)};
  padding-bottom: ${scale(0.5)};
  color: ${props => props.theme.color.grayLighter};
  background: ${props => props.theme.gradient.grayDarker};

  ${media.sm`
    padding-top: ${scale(5)};
    padding-bottom: ${scale(1)};
  `}
`;

const Main = styled(Grid)`
  padding-bottom: ${scale(1.5)};

  ${media.sm`
    padding-bottom: ${scale(3.5)};
  `}
`;

export default props => {
  const [locale] = useContext(LocalizationContext);
  const data = useStaticQuery(graphql`
    {
      craftcms {
        de: globals(site: "de") {
          ...footerData
        }
        dk: globals(site: "dk") {
          ...footerData
        }
        en: globals(site: "en") {
          ...footerData
        }
        it: globals(site: "it") {
          ...footerData
        }
        es: globals(site: "es") {
          ...footerData
        }
        pt: globals(site: "pt") {
          ...footerData
        }
        hu: globals(site: "hu") {
          ...footerData
        }
        ru: globals(site: "ru") {
          ...footerData
        }
      }
    }
  `);

  const LocalizedData = locale
    ? data.craftcms[locale]
    : data.craftcms[Object.keys(data.craftcms)[0]];

  const navigationGroups = LocalizedData.footer.navigationGroups.map(group => ({
    id: group.id,
    heading: group.heading,
    items: group.items.map(item => ({
      id: item.id,
      label: item.label,
      path: item.target[0] && getPath(item.target[0].uri),
    })),
  }));

  const legalNavigationItems = LocalizedData.footer.legal.map(item => ({
    id: item.id,
    label: item.label,
    path: item.target[0] && getPath(item.target[0].uri),
  }));

  return (
    <Footer {...props}>
      <Grid.Container>
        <Main>
          <Contact
            w={[1, 6 / 12, 4 / 12]}
            heading={LocalizedData.footer.contactHeading}
            buttonLabel={LocalizedData.footer.contactButtonLabel}
            buttonPath={
              LocalizedData.footer.contactButtonTarget[0] &&
              getPath(LocalizedData.footer.contactButtonTarget[0].uri)
            }
          />

          <Navigation
            w={[1, 6 / 12, 8 / 12, 6 / 12]}
            o={[0, 0, 0, 2 / 12]}
            navigationGroups={navigationGroups}
          />
        </Main>

        <Secondary />

        <Legal navigationItems={legalNavigationItems} />
      </Grid.Container>
    </Footer>
  );
};

export const query = graphql`
  fragment footerData on CraftCMS_GlobalsSet {
    footer {
      navigationGroups {
        ... on CraftCMS_NavigationGroupsGroup {
          id
          heading
          items {
            id
            label
            target {
              uri
            }
          }
        }
      }
      legal: navigationList {
        id
        label
        target {
          uri
        }
      }
      contactHeading
      contactButtonLabel
      contactButtonTarget {
        uri
      }
    }
  }
`;
