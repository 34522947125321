import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import LocalizationContext from '@/context/LocalizationContext';
import { localesData } from '@/utils/localization';

import Logo from '@/atoms/Logo';
import { media } from '@/styles/utils';

const HeaderLogo = styled(Logo)`
  width: 126px;
  height: auto;
  fill: ${props => props.theme.color.grayLighter};

  ${media.md`
    width: 152px;
  `}
`;

export default props => {
  const [locale] = useContext(LocalizationContext);
  const { baseUrl } = localesData[locale];
  return (
    <Link to={baseUrl} {...props}>
      <HeaderLogo />
    </Link>
  );
};
