import React, { useState, useContext, useRef } from 'react';

import ContactContext from '@/context/ContactContext';

import Header from '@/components/Header';
import ContentSection from '@/components/ContentSection';

import Wrapper from './Wrapper';

export default ({
  showSubnavigation = false,
  showContactButton = false,
  contactButtonLabel,
  sections,
  ...pageData
}) => {
  const contentSections = sections.map(section => ({
    ...section,
    id: `section-${section.id}`,
    ref: useRef(null),
    isContactSection:
      section.modules &&
      section.modules.length > 0 &&
      section.modules.some(
        module => module.formType && module.formType === 'contactForm'
      ),
  }));

  const contactSection = contentSections.filter(
    section => section.isContactSection
  );

  const subnavigationItems = contentSections.filter(
    section => section.isSubnavigationItem
  );

  const subnavigationItemsNormalized = subnavigationItems.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.id]: { ...curr },
    }),
    {}
  );

  const [activeSubnavigationItem, setActiveSubnavigationItem] = useState(
    subnavigationItems.length ? subnavigationItems[0].id : null
  );

  const [, contactDispatch] = useContext(ContactContext);

  const handleWaypointTraverse = (waypointObj, sectionId) => {
    const { previousPosition, currentPosition } = waypointObj;

    // scrolling down and the waypoint hits the top of the viewport
    if (currentPosition === 'above' && previousPosition === 'inside') {
      setActiveSubnavigationItem(sectionId);
    }

    // scrolling up and the waypoint detaches from the top of the viewport
    if (currentPosition === 'inside' && previousPosition === 'above') {
      const sectionKeys = Object.keys(subnavigationItemsNormalized);
      const sectionIndex = sectionKeys.indexOf(sectionId);
      const nextSectionIndex = sectionIndex !== 0 ? sectionIndex - 1 : 0;

      setActiveSubnavigationItem(
        subnavigationItemsNormalized[sectionKeys[nextSectionIndex]].id
      );
    }
  };

  const handleContactWaypointTraverse = waypointObj => {
    const { previousPosition, currentPosition } = waypointObj;

    if (currentPosition === 'inside' && previousPosition === 'below') {
      // entering the contact section
      contactDispatch({ type: 'SET_BUTTON_VISIBILITY', value: false });
    }
    if (currentPosition === 'below' && previousPosition === 'inside') {
      // leaving the contact section
      contactDispatch({ type: 'SET_BUTTON_VISIBILITY', value: true });
    }
  };

  return (
    <>
      <Header
        showSubnavigation={showSubnavigation}
        showContactButton={showContactButton}
        contactButtonLabel={contactButtonLabel}
        contactSectionRef={contactSection.length > 0 && contactSection[0].ref}
        subnavigationItems={subnavigationItems}
        activeSubnavigationItem={activeSubnavigationItem}
      />

      <Wrapper showSubnavigation={showSubnavigation}>
        {contentSections.map(
          ({ id, modules, isSubnavigationItem, isContactSection, ref }) => (
            <ContentSection
              key={id}
              modules={modules}
              sectionRef={ref}
              isWaypoint={isSubnavigationItem || isContactSection}
              onWaypointTraverse={waypointObj =>
                isContactSection
                  ? handleContactWaypointTraverse(waypointObj)
                  : handleWaypointTraverse(waypointObj, id)
              }
              {...pageData}
            />
          )
        )}
      </Wrapper>
    </>
  );
};
