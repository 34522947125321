import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';

import LocalizationContext from '@/context/LocalizationContext';

import SubmitButton from '@/atoms/SubmitButton';
import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

import Field from './Field';
import Checkbox from './Checkbox';

import { media } from '@/styles/utils';

const ButtonWrapper = styled(Grid.Item)`
  ${media.md`
    text-align: right;
  `}
`;

const Submit = styled(SubmitButton)`
  vertical-align: top;
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
`;

export default ({ name, fields, submitLabel, ...props }) => {
  const [, i18n] = useContext(LocalizationContext);
  const [formState, setFormState] = useState('default'); // default/sending/sent/error

  const formFields = [
    ...fields,
    {
      name: 'dataProtection',
      label: i18n._(
        t(
          'form.dataProtection.errorMessage'
        )`Datenschutz ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.`
      ),
      errorMessage: i18n._(
        t(
          'form.dataProtection.button.accept'
        )`Bitte akzeptieren Sie die Datenschutzbestimmungen`
      ),
      width: 2 / 3,
      type: 'checkbox',
      isOptional: false,
    },
  ];

  const initialValues = formFields.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.type === 'checkbox' ? false : '',
    }),
    {}
  );

  const handleSubmit = async values => {
    if (formState !== 'default') {
      return;
    }

    setFormState('sending');

    try {
      await axios.post(`${process.env.GATSBY_SERVICES_ENDPOINT}/formSubmit`, {
        form: name,
        ...values,
      });

      setTimeout(() => {
        setFormState('sent');
      }, 800);
    } catch {
      setFormState('error');
    }
  };

  const validateFields = values => {
    const validations = Object.keys(values).reduce((acc, key, index) => {
      const message =
        formFields[index].errorMessage ||
        i18n._(
          t(
            'form.inputfield.errorMessage.emptyField'
          )`Bitte geben Sie ${formFields[index].errorMessageLabel} an`
        );

      // check if required value is empty or false (for checkboxes)
      if (!formFields[index].isOptional) {
        if (formFields[index].type === 'checkbox' && values[key] === false) {
          return {
            ...acc,
            [key]: message,
          };
        }

        if (
          formFields[index].type !== 'checkbox' &&
          values[key].trim() === ''
        ) {
          return {
            ...acc,
            [key]: message,
          };
        }
      }
      // do field specific validation
      if (
        formFields[index].validation &&
        !formFields[index].validation.check(values[key])
      ) {
        return {
          ...acc,
          [key]: formFields[index].validation.errorMessage,
        };
      }

      return acc;
    }, {});

    return validations;
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFields}
      onSubmit={handleSubmit}
    >
      {({ values, touched, errors, isSubmitting }) => {
        const validationProps = { values, touched, errors, isSubmitting };

        return (
          <Form {...props}>
            <Fieldset disabled={isSubmitting}>
              <Grid>
                {formFields.map(({ isTextArea, ...field }) => {
                  if (field.type === 'checkbox') {
                    return (
                      <Checkbox
                        key={field.name}
                        {...field}
                        {...validationProps}
                      />
                    );
                  }

                  return (
                    <Field
                      key={field.name}
                      component={isTextArea ? 'textarea' : 'input'}
                      {...field}
                      {...validationProps}
                    />
                  );
                })}

                <ButtonWrapper w={[1, 1, 1 / 3]} mt={[1]}>
                  <Submit submitState={formState} />
                </ButtonWrapper>

                {formState === 'error' && (
                  <Grid.Item w={[1]} mt={[1]}>
                    <Text textColor="red">
                      <Trans id="form.errorMessage.tryAgainLater">
                        Das Formular kann zur Zeit nicht abgesendet werden.
                        Bitte probieren Sie es später erneut oder rufen Sie uns
                        an.
                      </Trans>
                    </Text>
                  </Grid.Item>
                )}
              </Grid>
            </Fieldset>
          </Form>
        );
      }}
    </Formik>
  );
};
