import React from 'react';
import { graphql } from 'gatsby';

import EntryCardGrid from '@/components/EntryCardGrid';

export default ({
  entries,
  cardsWidth = 'w1_3',
  isSwipeableOnMobile = false,
  ...props
}) => (
  <EntryCardGrid
    entries={entries}
    baseCardWidth={cardsWidth}
    isSwipeableOnMobile={isSwipeableOnMobile}
    {...props}
  />
);

export const query = graphql`
  fragment contentModuleCardsData on CraftCMS_ModulesCards {
    id
    cardsWidth
    isSwipeableOnMobile
    entries {
      id
      type: __typename
      ... on CraftCMS_PageReference {
        ...cardReferenceData
      }
      ... on CraftCMS_PageSolution {
        ...cardSolutionData
      }
      ... on CraftCMS_BlogArticleArticle {
        ...cardBlogArticleData
      }
      ... on CraftCMS_PageProductAutomat {
        ...cardProductAutomatData
      }
      ... on CraftCMS_PageProductExtension {
        ...cardProductExtensionData
      }
    }
  }
`;
