import styled from 'styled-components';

import { media, scale } from '@/styles/utils';

export default styled.main`
  ${props =>
    props.showSubnavigation &&
    `
    padding-top: ${scale(props.theme.measure.header.secondaryBar[0])};
  `}

  ${media.md`
    ${props =>
      props.showSubnavigation &&
      `
      padding-top: ${scale(props.theme.measure.header.secondaryBar[1])};
    `}
  `}
`;
