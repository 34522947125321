import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';

import ProductCard from './ProductCard';

export default ({ products, ...props }) => (
  <Grid.Container mt={[0.5, 0.5, 1.5]} mb={[1]} {...props}>
    <Grid alignItems="stretch">
      {products.map(({ id, ...entry }) => (
        <ProductCard key={id} {...entry} />
      ))}
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleProductOverviewData on CraftCMS_ModulesProductOverview {
    id
    products {
      ... on CraftCMS_PageProductAutomat {
        id
        uri
        thumbnail {
          filename
          width
          height
          title
        }
        title
        shortDescription
        capacity
        dispensing
      }
    }
  }
`;
