import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import RichText from '@/atoms/RichText';

export default ({ richText, ...props }) => (
  <Grid.Container {...props}>
    <Grid>
      {richText && (
        <RichText
          as={Grid.Item}
          w={[1, 1, 8 / 12, 6 / 12]}
          o={[0, 0, 2 / 12, 3 / 12]}
          mb={[2, 2, 2.5]}
          htmlContent={richText.content}
        />
      )}
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleRichTextData on CraftCMS_ModulesRichText {
    id
    richText {
      content
    }
  }
`;
