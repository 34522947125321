import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

const columnWidth = [1, 1 / 2, 4 / 12, 3 / 12];
const firstColumnOffset = [0, 0, 2 / 12, 3 / 12];

export default ({
  hasAddress = true,
  hasPhoneEmail = true,
  emailAddress,
  ...props
}) => (
  <Grid.Container {...props}>
    <Grid>
      {hasAddress && (
        <Grid.Item w={columnWidth} mb={[2, 2, 2.5]} o={firstColumnOffset}>
          <Heading h={6} as="h5" alignment={['left', 'center']}>
            <Trans id="contact.address.heading">Anschrift</Trans>
          </Heading>

          <Text
            withUnderlinedLinks
            textColor="grayDarker"
            alignment={['left', 'center']}
          >
            <Trans id="contact.address.value">
              ApoStore GmbH
              <br />
              Uferstraße 10
              <br />
              45881 Gelsenkirchen
            </Trans>
          </Text>
        </Grid.Item>
      )}

      {hasPhoneEmail && (
        <Grid.Item
          w={columnWidth}
          mb={[2, 2, 2.5]}
          o={!hasAddress ? firstColumnOffset : [0]}
        >
          <Heading h={6} as="h5" alignment={['left', 'center']}>
            <Trans id="contact.mobileFaxEmail.heading">Telefon / Email</Trans>
          </Heading>

          <Text
            withUnderlinedLinks
            textColor="grayDarker"
            alignment={['left', 'center']}
          >
            <Trans id="contact.mobileFaxEmail.value">
              Tel. +49 209 94117–0
              <br />
              Fax +49 209 94117–15
            </Trans>
            {emailAddress && (
              <>
                <br />
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </>
            )}
          </Text>
        </Grid.Item>
      )}
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleContactInformationData on CraftCMS_ModulesContactInformation {
    id
    hasAddress
    hasPhoneEmail
    emailAddress
  }
`;
