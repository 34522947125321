import React from 'react';
import styled, { keyframes } from 'styled-components';

const loader = keyframes`
  to {
    opacity: 0.8;
    transform: translate(0, -5px);
  }
`;

const Dot = styled.div``;

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${Dot} {
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 50%;
    margin: 3px;
    margin-bottom: 0;
    animation: ${loader} 0.6s infinite alternate;

    :nth-child(2) {
      animation-delay: 0.2s;
    }

    :nth-child(3) {
      animation-delay: 0.4s;
    }
  }
`;

export default () => {
  return (
    <Spinner>
      <Dot />
      <Dot />
      <Dot />
    </Spinner>
  );
};
