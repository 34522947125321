import React from 'react';
import { graphql } from 'gatsby';

import Section from '@/atoms/Section';
import ContentModule from '@/components/ContentModule';
import Waypoint from './Waypoint';

export default ({
  modules,
  isWaypoint,
  onWaypointTraverse,
  sectionRef,
  title,
  id: pageId,
  ...pageData
}) => (
  <Section ref={sectionRef}>
    {isWaypoint && <Waypoint onWaypointTraverse={onWaypointTraverse} />}

    {modules.map(({ id, type, ...moduleProps }) => {
      const moduleData = { ...moduleProps, ...pageData, pageTitle: title };

      switch (type) {
        case 'CraftCMS_ModulesButton':
          return <ContentModule.Button key={id} {...moduleData} />;
        case 'CraftCMS_ModulesCards':
          return <ContentModule.Cards key={id} {...moduleData} />;
        case 'CraftCMS_ModulesCardsLatestBlog':
          return <ContentModule.CardsLatestBlog key={id} {...moduleData} />;
        case 'CraftCMS_ModulesContactInformation':
          return <ContentModule.ContactInformation key={id} {...moduleData} />;
        case 'CraftCMS_ModulesContactsList':
          return <ContentModule.ContactsList key={id} {...moduleData} />;
        case 'CraftCMS_ModulesContentCarousel':
          return <ContentModule.ContentCarousel key={id} {...moduleData} />;
        case 'CraftCMS_ModulesDataSheet':
          return <ContentModule.DataSheet key={id} {...moduleData} />;
        case 'CraftCMS_ModulesDownloadButton':
          return <ContentModule.DownloadButton key={id} {...moduleData} />;
        case 'CraftCMS_ModulesDownloadsList':
          return <ContentModule.DownloadsList key={id} {...moduleData} />;
        case 'CraftCMS_ModulesEntriesList':
          return <ContentModule.EntriesList key={id} {...moduleData} />;
        case 'CraftCMS_ModulesHeadline':
          return <ContentModule.Headline key={id} {...moduleData} />;
        case 'CraftCMS_ModulesHero':
          return <ContentModule.Hero key={id} {...moduleData} />;
        case 'CraftCMS_ModulesImages':
          return <ContentModule.Images key={id} {...moduleData} />;
        case 'CraftCMS_ModulesImagesText':
          return <ContentModule.ImagesText key={id} {...moduleData} />;
        case 'CraftCMS_ModulesIntro':
          return <ContentModule.Intro key={id} {...moduleData} />;
        case 'CraftCMS_ModulesQuote':
          return <ContentModule.Quote key={id} {...moduleData} />;
        case 'CraftCMS_ModulesRichText':
          return <ContentModule.RichText key={id} {...moduleData} />;
        case 'CraftCMS_ModulesProductIntro':
          return <ContentModule.ProductIntro key={id} {...moduleData} />;
        case 'CraftCMS_ModulesProductOverview':
          return <ContentModule.ProductOverview key={id} {...moduleData} />;
        case 'CraftCMS_ModulesTextColumns':
          return <ContentModule.TextColumns key={id} {...moduleData} />;
        case 'CraftCMS_ModulesForm':
          return <ContentModule.Form key={id} {...moduleData} />;
        case 'CraftCMS_ModulesEventsMagazine':
          return <ContentModule.EventsMagazine key={id} {...moduleData} />;
        case 'CraftCMS_ModulesVideo':
          return <ContentModule.Video key={id} {...moduleData} />;
        default:
          return null;
      }
    })}
  </Section>
);

export const query = graphql`
  fragment contentSectionData on CraftCMS_ContentSectionsSuperTableBlockType {
    id
    label
    isSubnavigationItem
    modules {
      type: __typename
      ... on CraftCMS_ModulesButton {
        ...contentModuleButtonData
      }
      ... on CraftCMS_ModulesCards {
        ...contentModuleCardsData
      }
      ... on CraftCMS_ModulesCardsLatestBlog {
        ...contentModuleCardsLatestBlogData
      }
      ... on CraftCMS_ModulesContactInformation {
        ...contentModuleContactInformationData
      }
      ... on CraftCMS_ModulesContactsList {
        ...contentModuleContactsListData
      }
      ... on CraftCMS_ModulesContentCarousel {
        ...contentModuleContentCarouselData
      }
      ... on CraftCMS_ModulesDataSheet {
        ...contentModuleDataSheetData
      }
      ... on CraftCMS_ModulesDownloadButton {
        ...contentModuleDownloadButtonData
      }
      ... on CraftCMS_ModulesDownloadsList {
        ...contentModuleDownloadsListData
      }
      ... on CraftCMS_ModulesEntriesList {
        ...contentModuleEntriesListData
      }
      ... on CraftCMS_ModulesHeadline {
        ...contentModuleHeadlineData
      }
      ... on CraftCMS_ModulesHero {
        ...contentModuleHeroData
      }
      ... on CraftCMS_ModulesImages {
        ...contentModuleImagesData
      }
      ... on CraftCMS_ModulesImagesText {
        ...contentModuleImagesTextData
      }
      ... on CraftCMS_ModulesIntro {
        ...contentModuleIntroData
      }
      ... on CraftCMS_ModulesQuote {
        ...contentModuleQuoteData
      }
      ... on CraftCMS_ModulesRichText {
        ...contentModuleRichTextData
      }
      ... on CraftCMS_ModulesProductIntro {
        ...contentModuleProductIntroData
      }
      ... on CraftCMS_ModulesProductOverview {
        ...contentModuleProductOverviewData
      }
      ... on CraftCMS_ModulesTextColumns {
        ...contentModuleTextColumnsData
      }
      ... on CraftCMS_ModulesForm {
        ...contentModuleFormData
      }
      ... on CraftCMS_ModulesEventsMagazine {
        ...contentModuleEventsMagazineData
      }
      ... on CraftCMS_ModulesVideo {
        ...contentModuleVideoData
      }
    }
  }
`;
