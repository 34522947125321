import React, { useContext } from 'react';
import { t } from '@lingui/macro';

import LocalizationContext from '@/context/LocalizationContext';

import Form from '@/components/Form/';

export default ({ hasMessageField, ...props }) => {
  const [, i18n] = useContext(LocalizationContext);

  const fields = [
    {
      name: 'name',
      label: i18n._(t('form.name.label')`Name`),
      errorMessageLabel: i18n._(t('form.name.errorMessageLabel')`Ihren Namen`),
    },
    {
      name: 'company',
      label: i18n._(t('form.company.label')`Firma`),
      isOptional: true,
    },
    {
      name: 'street',
      label: i18n._(t('form.street.label')`Straße, Nr.`),
      errorMessageLabel: i18n._(
        t('form.street.errorMessageLabel')`eine Straße`
      ),
    },
    {
      name: 'postcode',
      label: i18n._(t('form.postcode.label')`PLZ`),
      errorMessageLabel: i18n._(
        t('form.postcode.errorMessageLabel')`eine Postleitzahl`
      ),
      width: 0.5,
    },
    {
      name: 'city',
      label: i18n._(t('form.city.label')`Ort`),
      errorMessageLabel: i18n._(t('form.city.errorMessageLabel')`einen Ort`),
      width: 0.5,
    },
    {
      name: 'country',
      label: i18n._(t('form.country.label')`Land`),
      errorMessageLabel: i18n._(t('form.country.errorMessageLabel')`ein Land`),
    },
    {
      name: 'email',
      label: i18n._(t('form.email.label')`Email`),
      errorMessageLabel: i18n._(
        t('form.email.errorMessageLabel')`Ihre Email-Adresse`
      ),
      isOptional: true,
      type: 'email',
    },
  ];

  if (hasMessageField) {
    fields.push({
      name: 'message',
      label: i18n._(t('form.message.label')`Nachricht`),
      errorMessageLabel: i18n._(
        t('form.message.errorMessageLabel')`eine Nachricht`
      ),
      isOptional: true,
      isTextArea: true,
    });
  }

  return <Form name="orderForm" fields={fields} {...props} />;
};
