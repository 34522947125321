import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import LocalizationContext from '@/context/LocalizationContext';

import EntryCardGrid from '@/components/EntryCardGrid';

export default ({ isSwipeableOnMobile = false, ...props }) => {
  const [locale] = useContext(LocalizationContext);

  const entriesData = useStaticQuery(graphql`
    {
      craftcms {
        de: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "de"
        ) {
          ...blogArticleEntry
        }

        dk: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "dk"
        ) {
          ...blogArticleEntry
        }

        en: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "en"
        ) {
          ...blogArticleEntry
        }

        it: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "it"
        ) {
          ...blogArticleEntry
        }

        es: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "es"
        ) {
          ...blogArticleEntry
        }

        pt: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "pt"
        ) {
          ...blogArticleEntry
        }

        hu: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "hu"
        ) {
          ...blogArticleEntry
        }

        ru: entries(
          type: BlogArticleArticle
          limit: 3
          orderBy: "postDate desc"
          site: "ru"
        ) {
          ...blogArticleEntry
        }
      }
    }
  `);

  const entries = entriesData.craftcms[locale];

  return (
    <EntryCardGrid
      entries={entries}
      baseCardWidth="w1_3"
      isSwipeableOnMobile={isSwipeableOnMobile}
      mt={[0.5, 0.5, 1.5]}
      mb={[1]}
      {...props}
    />
  );
};

export const query = graphql`
  fragment contentModuleCardsLatestBlogData on CraftCMS_ModulesCardsLatestBlog {
    id
    isSwipeableOnMobile
  }

  fragment blogArticleEntry on CraftCMS_BlogArticleArticle {
    id
    type: __typename
    ...cardBlogArticleData
  }
`;
