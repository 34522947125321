import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Image from '@/atoms/Image';

import { media, scale } from '@/styles/utils';

const ImageLink = styled(Link)`
  flex: 0 0 auto;
  display: block;
  padding: 0 ${scale(2.5)};
  outline: none;

  ${media.lg`
    padding: 0;
  `};
`;

export default ({ filename, link, srcsetSizes = '100vw', title, ...props }) => {
  const imageProps = {
    file: filename,
    sizes: srcsetSizes,
    alt: title,
    ...props,
  };

  return (
    <ImageLink rel="bookmark" tabIndex="-1" to={link}>
      <Image {...imageProps} />
    </ImageLink>
  );
};
