import React from 'react';
import styled from 'styled-components';

import Thumbnail from './Thumbnail';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';

import { media, scale } from '@/styles/utils';
import { getPath } from '@/utils/routes';
import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

const Thumb = styled(Thumbnail)``;

const Card = styled.article`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${props => props.theme.color.bg};
  border-radius: ${props => scale(props.theme.measure.borderRadius.base)};
  box-shadow: 0 6px 20px 0 rgba(147, 161, 173, 0.12);
  overflow: hidden;
  transform: translateY(0);
  transition: all 0.3s ease-out;

  @media (hover: hover) {
    :hover {
      box-shadow: 0 10px 20px 0 rgba(147, 161, 173, 0.16);
      transform: translateY(-2px);
    }
  }

  ${media.md`
    ${props =>
      props.layout === 'horizontal' &&
      `
      flex-direction: row;
    `}
  `}

  ${Thumb} {
    border-radius: ${props => scale(props.theme.measure.borderRadius.base)} 0 0;
    overflow: hidden;

    ${media.md`
      ${props =>
        props.layout === 'horizontal' &&
        `
        flex: 0 0 50%;
      `}
    `}
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${scale(0.75)} ${scale(1)} ${scale(1.25)};

  ${media.md`
    padding: ${scale(1)} ${scale(1.5)} ${scale(1.5)};

    ${props =>
      props.layout === 'horizontal' &&
      `
      flex: 0 0 50%;
      padding-left: ${scale(2.25)};
    `}
  `}
`;

export default ({
  uri = null,
  layout = 'default',
  thumbnail = null,
  thumbnailWidth,
  label = null,
  labelSize = 'small',
  heading,
  headingSize = 'default',
  subHeading = null,
  subHeadingSize = 'default',
  text = null,
  footerText = null,
  footerAlignBottom = true,
  spacing = 'default',
  hasButton = false,
  buttonLabel = 'Default Label',
  children,
  ...props
}) => {
  const link = uri && getPath(uri);

  return (
    <Card layout={layout} {...props}>
      {thumbnail && (
        <Thumb
          link={link}
          image={thumbnail.filename}
          alt={thumbnail.title}
          srcsetSizes={getSrcsetSizesFromGridWidths(thumbnailWidth)}
        />
      )}

      <Content layout={layout}>
        <Header
          link={link}
          label={label}
          labelSize={labelSize}
          heading={heading}
          headingSize={headingSize}
          subHeading={subHeading}
          subHeadingSize={subHeadingSize}
        />

        {text && <Main grow={footerAlignBottom}>{text}</Main>}

        <Footer
          spacing={spacing}
          text={footerText}
          link={link}
          hasButton={hasButton}
          buttonLabel={buttonLabel}
        />
      </Content>
    </Card>
  );
};
