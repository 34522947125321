import React from 'react';

import Text from '@/atoms/Text';

export default ({ children, ...props }) => {
  // find links in the format [lorem ipsum](https://example.com)
  const regex = /\[([^\]]*)\]\(([^\)]*)\)/g;

  if (typeof children === 'string') {
    const htmlContent = children.replace(regex, '<a href="$2">$1</a>');

    return (
      <Text
        withUnderlinedLinks
        dangerouslySetInnerHTML={{ __html: htmlContent }}
        {...props}
      />
    );
  }

  return (
    <Text withUnderlinedLinks {...props}>
      {children}
    </Text>
  );
};
