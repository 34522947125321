import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';
import Image from '@/atoms/Image';

import { scale } from '@/styles/utils';
import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

const Poster = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  pointer-events: ${props => (props.isVisible ? 'all' : 'none')};
  transition: opacity 0.5s ease-out;
`;

const PosterImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${props => props.theme.color.grayLighter};
  width: ${scale(5)};
  height: ${scale(5)};
  border-radius: 50%;
  color: ${props => props.theme.color.grayLighter};
  transform: translate(-50%, -50%);
`;

export default ({ image, imageWidth, isVisible, ...props }) => (
  <Poster isVisible={isVisible} {...props}>
    <PosterImage
      file={image.filename}
      aspectRatio={[16, 9]}
      alt={image.title}
      sizes={getSrcsetSizesFromGridWidths(imageWidth)}
    />

    <PlayButton>
      <Icon size="large" type="play" />
    </PlayButton>
  </Poster>
);
