import styled from 'styled-components';

import { scale, media } from '@/styles/utils';

export default styled.div.attrs(({ w, o, mt, mb, theme }) => ({
  w: w
    ? w.map(width => (width ? `${width * 100}%` : 'auto'))
    : ['auto', 'auto', 'auto', 'auto'],
  o: o ? o.map(offset => (offset ? `${offset * 100}%` : 0)) : [0, 0, 0, 0],
  mt: mt ? mt.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  mb: mb ? mb.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  halfGutter: theme.grid.gutter.map(g => (g ? scale(g / 2) : 'auto')),
}))`
  flex: 0 0 auto;
  margin-top: ${props => props.mt[0]};
  margin-bottom: ${props => props.mb[0]};
  margin-left: ${props => props.o[0]};
  padding-left: ${props => props.halfGutter[0]};
  padding-right: ${props => props.halfGutter[0]};
  width: ${props => props.w[0]};

  ${media.sm`
    margin-top: ${props => props.mt[1]};
    margin-bottom: ${props => props.mb[1]};
    margin-left: ${props => props.o[1]};
    padding-left: ${props => props.halfGutter[1]};
    padding-right: ${props => props.halfGutter[1]};
    width: ${props => props.w[1]};
  `}

  ${media.md`
    margin-top: ${props => props.mt[2]};
    margin-bottom: ${props => props.mb[2]};
    margin-left: ${props => props.o[2]};
    padding-left: ${props => props.halfGutter[2]};
    padding-right: ${props => props.halfGutter[2]};
    width: ${props => props.w[2]};
  `}

  ${media.lg`
    margin-top: ${props => props.mt[3]};
    margin-bottom: ${props => props.mb[3]};
    margin-left: ${props => props.o[3]};
    padding-left: ${props => props.halfGutter[3]};
    padding-right: ${props => props.halfGutter[3]};
    width: ${props => props.w[3]};
  `}
`;
