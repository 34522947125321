import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import FormattedText from '@/atoms/FormattedText';
import Button from '@/atoms/Button';

const headingProps = {
  default: { h: 4, as: 'h3', mb: [0.5, 0.5, 1] },
  small: { h: 5, as: 'h4', mb: [0.5, 0.5, 1] },
  label: { h: 6, as: 'h5' },
};

const columnAlignments = {
  left: 'flex-start',
  centered: 'center',
};

const columnWidths = {
  w1_3: [1, 1, 1 / 3],
  w1_4: [1, 1, 1 / 4],
  w1_6: [1, 1, 1 / 6],
};

const itemsPerRow = {
  w1_3: 3,
  w1_4: 4,
  w1_6: 6,
};

const ButtonWrapper = styled(Grid.Item)`
  text-align: center;
`;

export default ({
  columns,
  columnWidth = 'w1_3',
  columnAlignment = 'left',
  headingSize = 'default',
  expandOnClick,
  expandButtonLabel,
  ...props
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const itemsFirstRow = itemsPerRow[columnWidth];

  const items = columns.map(({ heading, text }, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <Grid.Item key={i} w={columnWidths[columnWidth]} mb={[2, 2, 3]}>
      <Heading {...headingProps[headingSize]}>{heading}</Heading>
      <FormattedText t={-1}>{text}</FormattedText>
    </Grid.Item>
  ));

  return (
    <Grid.Container mt={[0, 0, 1.5]} {...props}>
      <Grid justifyContent={columnAlignments[columnAlignment]}>
        {items.slice(0, itemsFirstRow)}

        {expandOnClick && columns.length > itemsFirstRow && !isExpanded ? (
          <ButtonWrapper w={[1]}>
            <Button
              appearance="secondary"
              onClick={() => {
                setExpanded(true);
              }}
            >
              {expandButtonLabel}
            </Button>
          </ButtonWrapper>
        ) : (
          items.slice(itemsFirstRow, columns.length)
        )}
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment contentModuleTextColumnsData on CraftCMS_ModulesTextColumns {
    id
    columns {
      heading
      text
    }
    columnWidth
    columnAlignment
    headingSize
    expandOnClick
    expandButtonLabel
  }
`;
