import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { graphql } from 'gatsby';

import { media, scale } from '@/styles/utils';

import Grid from '@/atoms/Grid';
import CarouselItem from './CarouselItem';
import CarouselArrows from './CarouselArrows';
import CarouselNavigation from './CarouselNavigation';

const Carousel = styled.div`
  margin-bottom: ${scale(1.5)};

  ${media.md`
    margin-bottom: ${scale(2.5)};
  `}
`;

const SlidesWrapper = styled.div`
  /* visually "crop" scrollbar */
  margin-top: -20px;
  overflow: hidden;
`;

const Slides = styled(animated.div)`
  display: flex;
  flex-wrap: nowrap;
  margin-top: ${scale(0.5)};
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  /* visually "crop" scrollbar */
  padding-bottom: 20px;
  transform: translateY(20px);

  ${media.md`
    margin-top: ${scale(2)};
    overflow-x: hidden;
    scroll-snap-type: none;
  `}

  * {
    ${media.belowmd`
      -webkit-user-drag: none;
      user-select: none;
    `}
  }
`;

export default ({ carousel, ...props }) => {
  const numSlides = carousel[0] && carousel[0].slides.length;

  const carouselRef = useRef(null);
  const [activeItem, setActiveItem] = useState(0);
  const [scrollPos, setScroll] = useSpring(() => ({ x: 0 }));

  const goToSlide = itemIndex => {
    const positionToGoTo = itemIndex * carouselRef.current.clientWidth;
    setScroll({ x: positionToGoTo });
  };

  useEffect(() => {
    const handleResize = () => goToSlide(activeItem);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeItem]);

  const handleScroll = () => {
    const { scrollLeft, clientWidth } = carouselRef.current;
    const nextActiveItem = Math.round(scrollLeft / clientWidth);

    if (nextActiveItem !== activeItem) {
      setActiveItem(nextActiveItem);
    }
  };

  return carousel[0] ? (
    <Carousel {...props}>
      <SlidesWrapper>
        <Slides
          ref={carouselRef}
          scrollLeft={scrollPos.x}
          onScroll={handleScroll}
        >
          {carousel[0].slides.map(slide => (
            <CarouselItem key={slide.label} {...slide} />
          ))}
        </Slides>
      </SlidesWrapper>

      <CarouselArrows
        handleNext={() =>
          activeItem < numSlides - 1 && goToSlide(activeItem + 1)
        }
        handlePrev={() => activeItem > 0 && goToSlide(activeItem - 1)}
        activeSlide={activeItem}
        numSlides={numSlides}
      />
      <Grid.Container>
        <CarouselNavigation
          activeSlide={activeItem}
          handleSet={i => goToSlide(i)}
          items={carousel[0].slides.map(({ label }) => label)}
        />
      </Grid.Container>
    </Carousel>
  ) : null;
};

export const query = graphql`
  fragment contentModuleContentCarouselData on CraftCMS_ModulesContentCarousel {
    id
    carousel {
      ... on CraftCMS_ContentCarousel {
        slides {
          label
          heading
          richText {
            content
          }
          image {
            filename
            width
            height
            title
          }
        }
      }
    }
  }
`;
