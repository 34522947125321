import React, { useState } from 'react';
import styled from 'styled-components';

import Section from './Section';

const Accordion = styled.div``;

export default ({ sections, firstSectionExpanded = false, ...props }) => {
  const [activeSection, setActiveSection] = useState(
    firstSectionExpanded ? 0 : null
  );

  return (
    <Accordion {...props}>
      {sections.map(({ id, title, content }, i) => (
        <Section
          key={id}
          num={i + 1}
          isActive={i === activeSection}
          handleToggle={() => setActiveSection(i === activeSection ? null : i)}
          title={title}
          content={content}
        />
      ))}
    </Accordion>
  );
};
