import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { media, scale } from '@/styles/utils';

const Quote = styled(Heading).attrs({
  h: 3,
  as: 'p',
  alignment: ['left', 'left', 'center'],
})`
  padding-left: ${scale(1.5)};
  color: ${props => props.theme.color.grayMedium};

  ${media.md`
    padding-left: 0;
  `};

  ::before,
  ::after {
    content: '»';
    display: inline-block;
    width: ${scale(1.5)};
    text-align: center;
    color: ${props => props.theme.color.grayLight};
  }

  ::before {
    content: '«';
    margin-left: -${scale(1.5)};

    ${media.md`
      margin-left: 0;
    `};
  }
`;

const Source = styled(Text).attrs({
  t: -1,
  alignment: ['left', 'left', 'center'],
})`
  padding-left: ${scale(2.5)};

  ${media.md`
    padding-left: 0;
  `}
`;

export default ({ quote, source, ...props }) => (
  <Grid.Container mt={[1.5, 1.5, 2]} mb={[1.5, 1.5, 2]} {...props}>
    <Grid>
      <Grid.Item
        as="blockquote"
        w={[1, 1, 10 / 12, 8 / 12]}
        o={[0, 0, 1 / 12, 2 / 12]}
        mb={[1.5, 1.5, 2]}
      >
        <Quote>{quote}</Quote>

        {source && <Source mt={[1]}>{source}</Source>}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleQuoteData on CraftCMS_ModulesQuote {
    id
    quote
    source
  }
`;
