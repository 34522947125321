import React from 'react';
import styled from 'styled-components';
import { Waypoint } from 'react-waypoint';

import theme from '@/styles/theme';

const mainBarHeightMD = theme.measure.header.mainBar[1] * 16;
const secondaryBarHeightMD = theme.measure.header.secondaryBar[1] * 16;
const waypointOffsetTop = mainBarHeightMD + secondaryBarHeightMD;

const PositionedWaypoint = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

export default ({ onWaypointTraverse, ...props }) => (
  <PositionedWaypoint {...props}>
    <Waypoint
      topOffset={waypointOffsetTop}
      onPositionChange={onWaypointTraverse}
    />
  </PositionedWaypoint>
);
