import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

export default ({
  data: { uri, thumbnail, title, abstract },
  width,
  ...props
}) => (
  <Card
    uri={uri}
    thumbnail={thumbnail && thumbnail[0]}
    thumbnailWidth={width}
    heading={title}
    headingSize="large"
    text={abstract}
    hasButton
    buttonLabel={<Trans id="viewButton.label">Ansehen</Trans>}
    {...props}
  />
);

export const query = graphql`
  fragment cardProductAutomatData on CraftCMS_PageProductAutomat {
    title
    abstract
    thumbnail {
      filename
      title
    }
    uri
  }
`;
