import React from 'react';
import styled from 'styled-components';

import Heading from '@/atoms/Heading';
import Icon from '@/atoms/Icon';

import { media, scale } from '@/styles/utils';

const Section = styled.div`
  border: 1px solid ${props => props.theme.color.grayLight};
  border-width: 0 0 1px;

  :first-child {
    border-width: 1px 0;
  }
`;

const HeadingCaret = styled(Icon)`
  margin-right: ${scale(0.5)};
  color: ${props => props.theme.color.grayMedium};
  transition: color 0.1s ease-out;
`;

const HeadingButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  width: 100%;
  height: ${scale(4)};
  text-align: left;
  color: ${props => props.theme.color.grayDarker};
  background: transparent;
  cursor: pointer;
  outline: none;

  :hover {
    ${HeadingCaret} {
      color: ${props => props.theme.color.grayDark};
    }
  }
`;

const Content = styled.div`
  display: ${props => (props.isVisible ? 'block' : 'none')};
  padding: ${scale(0.25)} 0 ${scale(0.5)};

  ${media.md`
    padding:  ${scale(1)} 0 ${scale(2)};
  `}
`;

export default ({ num, isActive, handleToggle, title, content, ...props }) => {
  const headerId = `header${num}`;
  const sectionId = `section${num}`;

  return (
    <Section {...props}>
      <Heading h={5} as="h4">
        <HeadingButton
          onClick={handleToggle}
          id={headerId}
          aria-expanded={isActive ? 'true' : 'false'}
          aria-controls={sectionId}
        >
          {title}

          <HeadingCaret type={isActive ? 'caretUp' : 'caretDown'} />
        </HeadingButton>
      </Heading>

      <Content
        isVisible={isActive}
        id={sectionId}
        aria-labelledby={headerId}
        role="region"
      >
        {content}
      </Content>
    </Section>
  );
};
