import { css } from 'styled-components';

import theme from '@/styles/theme';

/**
 * scale measurements
 */
export const scale = value => `${value * theme.scale}rem`;

/**
 * convert px to rem
 */
export const rem = val => `${val / 16}rem`;

/**
 * media queries
 */
const sizeNames = ['sm', 'md', 'lg'];

export const media = theme.breakpoints.reduce((acc, size, i) => {
  acc[sizeNames[i]] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)};
    }
  `;

  acc[`below${sizeNames[i]}`] = (...args) => css`
    @media (max-width: ${size}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

/**
 * visually hide an element
 */
export const visuallyHidden = css`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
