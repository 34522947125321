import React from 'react';

import Reference from './Reference';
import Solution from './Solution';
import BlogArticle from './BlogArticle';
import ProductAutomat from './ProductAutomat';
import ProductExtension from './ProductExtension';

export default ({ type, data, width, ...props }) => {
  switch (type) {
    case 'CraftCMS_PageReference':
      return <Reference data={data} width={width} {...props} />;
    case 'CraftCMS_PageSolution':
      return <Solution data={data} width={width} {...props} />;
    case 'CraftCMS_BlogArticleArticle':
      return <BlogArticle data={data} width={width} {...props} />;
    case 'CraftCMS_PageProductAutomat':
      return <ProductAutomat data={data} width={width} {...props} />;
    case 'CraftCMS_PageProductExtension':
      return <ProductExtension data={data} width={width} {...props} />;
    default:
      return null;
  }
};
