import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { buildURL } from 'react-imgix';

import LocalizationContext from '@/context/LocalizationContext';
import fonts from '@/styles/fonts';
import { getAssetUrl } from '@/utils/routes';

export default ({
  title,
  metaDescription,
  thumbnail,
  noIndex = false,
  ...props
}) => {
  const [locale, { language }] = useContext(LocalizationContext);

  const { craftcms, site } = useStaticQuery(graphql`
    fragment seoData on CraftCMS_GlobalsSet {
      seoDefaults {
        metaDescription
        thumbnail {
          filename
        }
      }
    }
    {
      craftcms {
        de: globals(site: "de") {
          ...seoData
        }
        dk: globals(site: "dk") {
          ...seoData
        }
        en: globals(site: "en") {
          ...seoData
        }
        it: globals(site: "it") {
          ...seoData
        }
        es: globals(site: "es") {
          ...seoData
        }
        pt: globals(site: "pt") {
          ...seoData
        }
        hu: globals(site: "hu") {
          ...seoData
        }
        ru: globals(site: "ru") {
          ...seoData
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { seoDefaults } = craftcms[locale];
  const description = metaDescription || seoDefaults.metaDescription;
  const thumbnailUrl =
    thumbnail && thumbnail.length > 0
      ? getAssetUrl(thumbnail[0].filename)
      : getAssetUrl(seoDefaults.thumbnail[0].filename);
  const ogImage = buildURL(thumbnailUrl, {
    w: 1200,
    h: 630,
    fit: 'crop',
  });
  const ogTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      defaultTitle={site.siteMetadata.title}
      {...props}
    >
      <html lang={language} />

      <style type="text/css">{fonts}</style>

      <meta name="description" content={description} />

      <meta name="og:title" content={ogTitle} />
      <meta name="og:description" content={description} />
      <meta property="og:image" content={ogImage} />
      <meta name="og:type" content="website" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={description} />

      {noIndex && <meta name="robots" content="noindex,follow" />}

    </Helmet>
  );
};
