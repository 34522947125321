import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Heading from '@/atoms/Heading';
import Icon from '@/atoms/Icon';

import { scale } from '@/styles/utils';

const List = styled.ul`
  list-style: none;
`;

const ListItem = styled(Heading).attrs({ h: 5, as: 'li' })`
  border: 1px solid ${props => props.theme.color.grayLight};
  border-width: 0 0 1px;

  :first-child {
    border-width: 1px 0;
  }
`;

const ItemIcon = styled(Icon)`
  margin-right: ${scale(0.5)};
  color: ${props => props.theme.color.grayMedium};
  transition: color 0.1s ease-out;
`;

const ItemLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${scale(4)};
  outline: none;

  :hover {
    ${ItemIcon} {
      color: ${props => props.theme.color.grayDark};
    }
  }
`;

export default ({ items, icon, ...props }) => (
  <List {...props}>
    {items.map(({ title, ...itemProps }) => (
      <ListItem key={title}>
        <ItemLink as={itemProps.to ? Link : 'a'} {...itemProps}>
          {title}

          {icon && <ItemIcon type={icon} />}
        </ItemLink>
      </ListItem>
    ))}
  </List>
);
