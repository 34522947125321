import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

const NavContainer = styled(Grid).attrs({ as: 'ul' })`
  list-style: none;
`;

const NavGroup = styled(Grid.Item).attrs({ as: 'li' })`
  padding-bottom: ${scale(1)};
`;

const NavList = styled.ul`
  list-style: none;
`;

const NavListItem = styled(Text).attrs({ t: -1, as: 'li' })`
  padding-bottom: ${scale(0.25)};

  & > a {
    color: ${props => props.theme.color.grayLighter};
    text-decoration: none;
    transition: color 0.1s ease-out;

    :hover,
    :focus {
      color: ${props => props.theme.color.grayMediumLight};
    }

    :active {
      color: ${props => props.theme.color.grayMedium};
    }
  }
`;

export default ({ navigationGroups, ...props }) => (
  <Grid.Item as="nav" {...props}>
    <NavContainer>
      {navigationGroups.map(group => (
        <NavGroup w={[1, 1, 1 / 3]} key={group.id}>
          <Heading h={6} mb={[0.5]} as="h4">
            {group.heading}
          </Heading>

          <NavList>
            {group.items.map(item => (
              <NavListItem key={item.id}>
                <Link to={item.path}>{item.label}</Link>
              </NavListItem>
            ))}
          </NavList>
        </NavGroup>
      ))}
    </NavContainer>
  </Grid.Item>
);
