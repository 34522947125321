/* eslint-disable import/prefer-default-export */

import theme from '@/styles/theme';

/**
 * get sizes string (for srcset) from grid-item width array
 */
export const getSrcsetSizesFromGridWidths = widths => {
  const numBreakpoints = theme.breakpoints.length + 1;

  // the width array could be shorter than the number of breakpoints
  const widthsSanitized = Array(4)
    .fill(0)
    .map((val, i) => widths[i] || widths[widths.length - 1]);

  // convert widths to size for each breakpoint
  const sizesArray = widthsSanitized.map((width, i) => {
    if (i === numBreakpoints - 1) {
      return `${Math.ceil(width * 10 * theme.grid.maxWidth) / 10}em`;
    }

    return `(max-width: ${theme.breakpoints[i]}) ${Math.ceil(width * 100)}vw`;
  });

  // build string from sizes
  return sizesArray.join(', ');
};
