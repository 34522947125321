import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import EntryCard from '@/components/EntryCard';

import { media, scale } from '@/styles/utils';

const widths = {
  w1_1: [1],
  w1_2: [1, 1, 1 / 2],
  w1_3: [1, 1, 1 / 3],
  w1_4: [1, 1, 1 / 4],
};

const ScrollbarCropContainer = styled.div`
  padding-top: ${scale(0.5)};
  margin-bottom: ${scale(1)};

  /* visually "crop" scrollbar */
  margin-top: -20px;
  overflow: hidden;

  ${media.md`
    padding-top: ${scale(1.5)};
  `}
`;

const CardItem = styled(Grid.Item)``;
const CardsHolder = styled(Grid)``;

const Container = styled(Grid.Container)`
  /* visually "crop" scrollbar */
  padding-bottom: 20px;
  transform: translateY(20px);

  ${media.belowmd`
    ${props =>
      props.isSwipeableOnMobile &&
      `
      scroll-snap-type: x mandatory;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;

      ${CardsHolder} {
        flex-wrap: nowrap;
        margin-left: -${scale(1)};
        margin-right: -${scale(1)};
      }

      ${CardItem} {
        box-sizing: content-box;
        width: calc(100vw - ${scale(2)});
        padding-left: ${scale(0.25)};
        padding-right: ${scale(0.25)};
        scroll-snap-align: center;

        :first-child {
          padding-left: ${scale(1)};
        }

        :last-child {
          padding-right: ${scale(1)};
        }

        * {
          -webkit-user-drag: none;
          user-select: none;
        }
      }
    `}
  `}
`;

export default ({ entries, isSwipeableOnMobile, baseCardWidth, ...props }) => (
  <ScrollbarCropContainer {...props}>
    <Container isSwipeableOnMobile={isSwipeableOnMobile}>
      <CardsHolder alignItems="stretch">
        {entries.map(({ id, type, cardWidth = 'w1_3', ...entry }) => {
          const width =
            baseCardWidth === 'individual'
              ? widths[cardWidth]
              : widths[baseCardWidth];

          return (
            <CardItem key={id} w={width} mb={[1, 1, 1.5]}>
              <EntryCard
                type={type}
                data={entry}
                width={width}
                widthName={cardWidth}
              />
            </CardItem>
          );
        })}
      </CardsHolder>
    </Container>
  </ScrollbarCropContainer>
);
