import React from 'react';
import { graphql } from 'gatsby';

import { ContactProvider } from '@/context/ContactContext';

import Head from '@/components/Head';
import PageContent from '@/components/PageContent';

export default ({ data }) => {
  const {
    showSubnavigation = false,
    showContactButton = false,
    contactButtonLabel,
    contentSections,
    title,
    metaDescription,
    thumbnail,
    noIndex = false,
    ...pageData
  } = data.craftcms.entry;

  if (contentSections) {
    return (
      <ContactProvider>
        <Head
          title={title === 'Home' ? null : title}
          metaDescription={metaDescription}
          thumbnail={thumbnail}
          noIndex={noIndex}
        />

        <PageContent
          showSubnavigation={showSubnavigation}
          showContactButton={showContactButton}
          contactButtonLabel={contactButtonLabel}
          sections={contentSections}
          title={title}
          {...pageData}
        />
      </ContactProvider>
    );
  }

  return null;
};

export const query = graphql`
  query($id: [Int], $locale: String) {
    craftcms {
      entry(id: $id, site: $locale) {
        title
        ... on CraftCMS_BlogArticleArticle {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_JobListing {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_Home {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_PageDefault {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_PageProductAutomat {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          ...pageProductAutomatData
          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_PageProductExtension {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          ...pageProductExtensionData
          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_PageSolution {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
        ... on CraftCMS_PageReference {
          metaDescription
          noIndex
          thumbnail {
            filename
          }

          showSubnavigation
          showContactButton
          contactButtonLabel: subnavigationContactButtonLabel
          contentSections {
            ...contentSectionData
          }
        }
      }
    }
  }
`;
