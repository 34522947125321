import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import LinkList from '@/components/LinkList';

import { getAssetUrl } from '@/utils/routes';

export default ({ assets, ...props }) => (
  <Grid.Container mb={[1.5, 1.5, 2.5]} {...props}>
    <Grid>
      <Grid.Item w={[1, 1, 8 / 12]} o={[0, 0, 2 / 12]}>
        {assets.length > 0 && (
          <LinkList
            icon="download"
            items={assets.map(({ filename, title }) => ({
              title,
              href: getAssetUrl(filename),
              target: '_blank',
              download: true,
            }))}
          />
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleDownloadsListData on CraftCMS_ModulesDownloadsList {
    id
    assets {
      filename
      title
    }
  }
`;
