// we're not using ES6 exports because we're also importing from gatsby-node

/**
 * get sanitized path with slashes
 */
const getPath = uri =>
  uri && (uri !== '' && uri !== '__home__') ? `/${uri}/` : '/';

module.exports.getPath = getPath;

/**
 * get imgix url for image
 */
const getAssetUrl = file => `https://apostore.imgix.net/${file}`;

module.exports.getAssetUrl = getAssetUrl;
