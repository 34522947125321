import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';

export default ({ text, ...props }) => (
  <Grid.Container {...props}>
    <Grid>
      <Grid.Item
        w={[1, 1, 8 / 12, 6 / 12]}
        o={[0, 0, 2 / 12, 3 / 12]}
        mb={[1.5, 1.5, 2]}
      >
        <Heading h={2} as="h2" alignment={['left', 'left', 'center']}>
          {text}
        </Heading>
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleHeadlineData on CraftCMS_ModulesHeadline {
    id
    text
  }
`;
