import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Button from '@/atoms/Button';

const Footer = styled.footer`
  text-align: center;
`;

export default ({ link, ...props }) => (
  <Footer {...props}>
    <Button to={link}>
      <Trans id="viewButton.label">Ansehen</Trans>
    </Button>
  </Footer>
);
