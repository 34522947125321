import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import LinkList from '@/components/LinkList';

import { getPath } from '@/utils/routes';

export default ({ entries, ...props }) => (
  <Grid.Container mb={[1.5, 1.5, 2.5]} {...props}>
    <Grid>
      <Grid.Item w={[1, 1, 8 / 12]} o={[0, 0, 2 / 12]}>
        {entries.length > 0 && (
          <LinkList
            icon="caretRight"
            items={entries.map(({ uri, title }) => ({
              title,
              to: getPath(uri),
            }))}
          />
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleEntriesListData on CraftCMS_ModulesEntriesList {
    id
    entries {
      uri
      title
    }
  }
`;
