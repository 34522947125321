import React from 'react';
import styled from 'styled-components';
import { Field, ErrorMessage } from 'formik';

import { scale } from '@/styles/utils';

import Text from '@/atoms/Text';
import Grid from '@/atoms/Grid';

const Wrapper = styled(Grid.Item)`
  position: relative;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ErrorMessageContainer = styled(Text).attrs({ t: -3, textColor: 'red' })`
  position: absolute;
  bottom: -${scale(1.25)};
`;

const Checkbox = styled(Field).attrs({ type: 'checkbox', component: 'input' })`
  display: block;
  margin-right: ${scale(0.5)};
`;

const Label = styled(Text).attrs({
  t: -2,
  as: 'label',
  textColor: 'grayDark',
})`
  display: block;
  width: 100%;
  max-width: ${scale(26)};
`;

export default ({ label, name, width = 1, values, type, ...props }) => {
  const fieldOptions = {
    id: name,
    name,
    checked: values[name],
    value: 'accepted',
  };

  return (
    <Wrapper w={[1, 1, width]} mt={[1]} mb={[1.5]} {...props}>
      <CheckboxContainer>
        <Checkbox {...fieldOptions} />

        <Label htmlFor={name}>{label}</Label>
      </CheckboxContainer>
      <ErrorMessageContainer>
        <ErrorMessage name={name} />
      </ErrorMessageContainer>
    </Wrapper>
  );
};
