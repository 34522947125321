import React from 'react';
import { ThemeProvider } from 'styled-components';
import { I18nProvider } from '@lingui/react';

import da from '@/locales/da/messages';
import de from '@/locales/de/messages';
import en from '@/locales/en/messages';
import es from '@/locales/es/messages';
import hu from '@/locales/hu/messages';
import it from '@/locales/it/messages';
import pt from '@/locales/pt/messages';
import ru from '@/locales/ru/messages';

import { LocalizationProvider } from '@/context/LocalizationContext';
import {
  baseLocale,
  getLangFromLocale,
  getLangFromPath,
} from '@/utils/localization';
import GlobalStyles from '@/styles/global';
import theme from '@/styles/theme';

//import CookieNotice from '@/components/CookieNotice';
import Footer from '@/components/Footer';

export default ({ pageContext, location, children }) => {
  const language = pageContext.locale
    ? getLangFromLocale(pageContext.locale)
    : getLangFromPath(location.pathname); // e.g. for 404 page

  return (
    <I18nProvider
      language={language || baseLocale}
      catalogs={{ da, de, en, es, hu, it, pt, ru }}
    >
      <LocalizationProvider>
        <GlobalStyles />

        <ThemeProvider theme={theme}>
          <>
            {children}


            <Footer />

          </>
        </ThemeProvider>
      </LocalizationProvider>
    </I18nProvider>
  );
};

/*
<CookieNotice />
*/
