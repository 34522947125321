import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import ContactInfo from '@/components/ContactInfo';

export default ({ contacts, ...props }) => (
  <Grid.Container {...props}>
    <Grid>
      {contacts.map(({ title, ...contactProps }) => (
        <Grid.Item key={title} w={[1, 1 / 2, 1 / 3]} mt={[1, 2]} mb={[3, 3, 4]}>
          <Heading h={5} as="h4" mb={[1, 1, 1.5]}>
            {title}
          </Heading>

          <ContactInfo {...contactProps} />
        </Grid.Item>
      ))}
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleContactsListData on CraftCMS_ModulesContactsList {
    id
    contacts {
      ... on CraftCMS_Contact {
        title
        address
        contactPerson
        mobile
        mobile2
        mobile3
        phone
        phone2
        phone3
        fax
        email
        email2
        email3
        website
      }
    }
  }
`;
