import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Button from '@/atoms/Button';

import { scale, media } from '@/styles/utils';

const Contact = styled(Grid.Item)`
  padding-bottom: ${scale(3.5)};

  ${media.sm`
    padding-bottom: 0;
  `}
`;

export default ({ heading, buttonLabel, buttonPath, ...props }) => (
  <Contact {...props}>
    {heading && (
      <Heading h={3} as="h3" mb={[1.5]} inverted>
        {heading}
      </Heading>
    )}

    {buttonLabel && buttonPath && (
      <Button appearance="secondaryInverted" to={buttonPath}>
        {buttonLabel}
      </Button>
    )}
  </Contact>
);
