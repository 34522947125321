import React from 'react';
import { graphql } from 'gatsby';
import { Trans } from '@lingui/macro';

import Card from '@/components/Card';

export default ({ data, width, ...props }) => {
  return (
    <Card
      uri={data.uri}
      thumbnail={data.thumbnail && data.thumbnail[0]}
      thumbnailWidth={width}
      heading={data.title}
      headingSize="large"
      label={data.category[0].title}
      text={data.abstract}
      hasButton
      buttonLabel={
        <Trans id="card.blogArticle.button.continueReading">Weiterlesen</Trans>
      }
      {...props}
    />
  );
};

export const query = graphql`
  fragment cardBlogArticleData on CraftCMS_BlogArticleArticle {
    title
    uri
    abstract
    category {
      title
    }
    thumbnail {
      filename
      title
    }
  }
`;
