import React from 'react';
import styled from 'styled-components';
import { Trans, NumberFormat } from '@lingui/macro';

import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';

import { media, scale } from '@/styles/utils';

const ProductStats = styled.dl`
  margin-bottom: ${scale(1.5)};
  border: 1px solid ${props => props.theme.color.grayLight};
  border-width: 1px 0;
  padding: ${scale(0.75)} 0 ${scale(0.5)};

  ${media.md`
    margin-bottom: ${scale(2)};
    padding-top: ${scale(1)};
    padding-bottom: ${scale(0.75)};
  `}
`;

const Label = styled(Heading).attrs({ h: 6, as: 'dt' })`
  display: inline-block;
  padding-bottom: ${scale(0.25)};
  width: ${scale(9)};
`;

const Data = styled(Text).attrs({ t: -2, as: 'dd', textColor: 'grayMedium' })`
  display: inline-block;
  padding-bottom: ${scale(0.25)};
  width: calc(100% - ${scale(9)});
`;

export default ({ capacity, throughput, dispensing, ...props }) => (
  <ProductStats {...props}>
    <Label>
      <Trans id="product.data.capacity.heading">Kapazität</Trans>
    </Label>
    <Data>
      <Trans id="product.data.capacity.value">
        bis zu
        <NumberFormat value={capacity} /> Packungen
      </Trans>
    </Data>

    <Label>
      <Trans id="product.data.dispensing.heading">Ein-/Auslagerung</Trans>
    </Label>
    <Data>
      <Trans id="product.data.dispensing.value">
        bis zu
        <NumberFormat value={dispensing} /> Packungen/h
      </Trans>
    </Data>
  </ProductStats>
);
