import React from 'react';
import styled from 'styled-components';

import Button from '@/atoms/Button';

import { media } from '@/styles/utils';

const ButtonDesktop = styled(Button)`
  ${media.belowmd`
    display: none;
  `}

  /* compensate for contact button on right side, so that navigation is centered */
  ${props =>
    props.isSpacer &&
    `
    opacity: 0;
    pointer-events: none;
  `}

  ${media.belowlg`
    ${props =>
      props.isSpacer &&
      `
      display: none;
    `}
  `}
`;

export default ({ label, onClick, isSpacer, ...props }) => (
  <ButtonDesktop
    size="small"
    appearance="secondary"
    isSpacer={isSpacer}
    onClick={onClick}
    {...props}
  >
    {label}
  </ButtonDesktop>
);
