import React from 'react';
import styled from 'styled-components';

import { media, scale } from '@/styles/utils';

import Icon from '@/atoms/Icon';

const Button = styled.button`
  width: ${scale(2.5)};
  height: ${scale(2.5)};
  border: 0;
  background: transparent;

  ${media.md`
    display: none;
  `}

  :focus {
    outline: none;
  }
`;

export default ({ isExpanded, ...props }) => (
  <Button {...props}>
    <Icon size="large" type={isExpanded ? 'close' : 'hamburger'} />
  </Button>
);
