import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    font-family: Graphik, sans-serif;
    font-size: 16px;
    color: #5e717d;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  #CybotCookiebotDialog {
    box-shadow: none !important;
  }
`;
