import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Heading from '@/atoms/Heading';

import { media, scale } from '@/styles/utils';

const Header = styled.header`
  margin-top: ${scale(1)};
  margin-bottom: ${scale(1)};

  ${media.md`
    margin-bottom: ${scale(0.75)};
  `}
`;

export default ({ link, heading, ...props }) => (
  <Header {...props}>
    <Heading h={4} alignment={['center']} as="h1">
      {link ? (
        <Link rel="bookmark" to={link}>
          {heading}
        </Link>
      ) : (
        heading
      )}
    </Heading>
  </Header>
);
