import React from 'react';
import styled from 'styled-components';

import Row from './Row';
import Heading from './Heading';
import Content from './Content';

const Container = styled.table`
  display: block;

  tbody {
    display: block;
  }
`;

const Table = ({ children, ...props }) => (
  <Container {...props}>
    <tbody>{children}</tbody>
  </Container>
);

Table.Row = Row;
Table.Heading = Heading;
Table.Content = Content;

export default Table;
