import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Image from '@/atoms/Image';
import Heading from '@/atoms/Heading';
import RichText from '@/atoms/RichText';

import { media, scale } from '@/styles/utils';
import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

const Item = styled.div`
  scroll-snap-align: center;
  width: 100%;
  flex: 0 0 auto;
`;

const Header = styled.header`
  margin-bottom: ${scale(0.5)};

  ${media.md`
    margin-bottom: ${scale(1)};
  `}
`;

const Label = styled(Heading).attrs({ h: 6 })`
  ${media.md`
    display: none;
  `}
`;

const imageWidth = [1, 1, 6 / 12];
const contentWidth = [1, 1, 5 / 12, 4 / 12];
const contentOffset = [0, 0, 1 / 12];

export default ({ label, heading, richText, image, ...props }) => (
  <Item as="article" {...props}>
    <Grid.Container>
      <Grid>
        {image.length > 0 && (
          <Grid.Item w={imageWidth}>
            <Image
              file={image[0].filename}
              width={image[0].width}
              height={image[0].height}
              alt={image[0].title}
              sizes={getSrcsetSizesFromGridWidths(imageWidth)}
            />
          </Grid.Item>
        )}

        <Grid.Item
          w={contentWidth}
          o={contentOffset}
          mt={[1, 1, 2]}
          mb={[0, 0, 0, 6]}
        >
          <Header>
            <Label as="h1">{label}</Label>

            {heading && (
              <Heading h={5} as="h2">
                {heading}
              </Heading>
            )}
          </Header>

          {richText && <RichText htmlContent={richText.content} />}
        </Grid.Item>
      </Grid>
    </Grid.Container>
  </Item>
);
