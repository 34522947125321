import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Button from '@/atoms/Button';

import { getAssetUrl } from '@/utils/routes';

const ButtonContainer = styled(Grid.Container)`
  text-align: center;
`;

export default ({ appearance, label, asset, ...props }) => (
  <ButtonContainer mb={[1.5, 1.5, 2.5]} {...props}>
    <Button
      href={asset.length > 0 && getAssetUrl(asset[0].filename)}
      download
      target="_blank"
      appearance={appearance}
      icon="download"
    >
      {label}
    </Button>
  </ButtonContainer>
);

export const query = graphql`
  fragment contentModuleDownloadButtonData on CraftCMS_ModulesDownloadButton {
    id
    appearance
    label
    asset {
      filename
    }
  }
`;
