import React from 'react';
import styled from 'styled-components';
import Hammer from 'react-hammerjs';

import useCarousel from '@/hooks/useCarousel';

import Slide from './Slide';
import Indicator from './Indicator';

import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

const Container = styled.div`
  position: relative;
`;

const Slides = styled(Hammer)`
  position: relative;
`;

export default ({ images = [], sizes = '100vw', ...props }) => {
  const isCarousel = images.length > 1;

  const [activeSlide, handlePrev, handleNext, handleSet] = useCarousel(
    images.length
  );

  const imageSizes = Array.isArray(sizes)
    ? getSrcsetSizesFromGridWidths(sizes)
    : sizes;

  const carouselInteractions = isCarousel
    ? {
        onSwipeLeft: handleNext,
        onSwipeRight: handlePrev,
      }
    : {};

  const slideInteractions = isCarousel
    ? {
        handleClick: handleNext,
      }
    : {};

  return (
    <Container {...props}>
      <Slides {...carouselInteractions}>
        <div>
          {images.length !== 0 &&
            images.map((image, i) => (
              <Slide
                key={image.filename}
                image={image}
                sizes={imageSizes}
                isActive={i === activeSlide}
                isCarousel={isCarousel}
                {...slideInteractions}
              />
            ))}
        </div>
      </Slides>

      {isCarousel && (
        <Indicator
          numSlides={images.length}
          activeSlide={activeSlide}
          handleSet={handleSet}
          withCaption={images[0].caption !== null}
        />
      )}
    </Container>
  );
};
