// we're not using ES6 exports because we're also importing from gatsby-node
/**
 * locale setup
 */
const localesData = {
  de: {
    baseUrl: '/',
    label: 'Deutsch',
  },
  en: {
    baseUrl: '/en/',
    label: 'English',
  },
  dk: {
    baseUrl: '/dk/',
    label: 'Dansk',
  },
  it: {
    baseUrl: '/it/',
    label: 'Italiano',
  },
  es: {
    baseUrl: '/es/',
    label: 'Español',
  },
  pt: {
    baseUrl: '/pt/',
    label: 'Português',
  },
  hu: {
    baseUrl: '/hu/',
    label: 'Magyar',
  },
  ru: {
    baseUrl: '/ru/',
    label: 'Русский',
  },
};

const locales = Object.keys(localesData);
const baseLocale = locales[0];

// maps languages to handles
const languages = {
  de: 'de',
  da: 'dk',
  en: 'en',
  it: 'it',
  es: 'es',
  pt: 'pt',
  hu: 'hu',
  ru: 'ru',
};

const languagesInverted = Object.keys(languages).reduce(
  (acc, curr) => ({
    ...acc,
    [languages[curr]]: curr,
  }),
  {}
);

// Locale = handle
const getLocaleFromLang = lang => languages[lang];

const getLangFromLocale = locale => languagesInverted[locale];

const getLangFromPath = path => {
  const matchedLocale = locales.find(locale => {
    const { baseUrl } = localesData[locale];

    return baseUrl !== '/' && path.includes(baseUrl);
  });

  return matchedLocale ? getLangFromLocale(matchedLocale) : null;
};

module.exports.locales = locales;
module.exports.baseLocale = baseLocale;
module.exports.localesData = localesData;
module.exports.getLocaleFromLang = getLocaleFromLang;
module.exports.getLangFromLocale = getLangFromLocale;
module.exports.getLangFromPath = getLangFromPath;

/**
 * graphql helpers
 */
// get content for a specific locale
const getQueryForLocale = ({ query, filters = null, selection, locale }) => `
  ${locale}: ${query} (
    site: "${locale}"
    ${filters && filters}
  ) {
    ${selection}
  }
`;

// get content for all locales in a single query
const getMergedQueryForLocales = props => `
  {
    craftcms {
      ${locales.map(locale => getQueryForLocale({ ...props, locale }))}
    }
  }
`;

module.exports.getMergedQueryForLocales = getMergedQueryForLocales;
