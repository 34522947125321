import styled from 'styled-components';

import { scale, media, visuallyHidden } from '@/styles/utils';
import { h1Hero, h1, h2, h3, h4, h5, h6 } from '@/styles/typography';

export default styled.h1.attrs(
  ({ h, hidden, alignment, mt, mb, inverted, textColor }) => ({
    h: h || 1,
    hidden: hidden || false,
    mt: mt ? mt.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
    mb: mb ? mb.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
    alignment: alignment || ['left', 'left', 'left', 'left'],
    inverted: inverted || false,
    textColor: textColor || null,
  })
)`
  ${props => props.h === 'hero' && h1Hero}
  ${props => props.h === 1 && h1}
  ${props => props.h === 2 && h2}
  ${props => props.h === 3 && h3}
  ${props => props.h === 4 && h4}
  ${props => props.h === 5 && h5}
  ${props => props.h === 6 && h6}

  ${props => props.hidden && visuallyHidden}

  ${props => props.inverted && `color: ${props.theme.color.grayLighter};`}
  ${props => props.textColor && `color: ${props.theme.color[props.textColor]};`}

  white-space: pre-line;
  margin-top: ${props => props.mt[0]};
  margin-bottom: ${props => props.mb[0]};
  text-align: ${props => props.alignment[0]};

  ${media.sm`
    margin-top: ${props => props.mt[1]};
    margin-bottom: ${props => props.mb[1]};
    text-align: ${props => props.alignment[1]};
  `}

  ${media.md`
    margin-top: ${props => props.mt[2]};
    margin-bottom: ${props => props.mb[2]};
    text-align: ${props => props.alignment[2]};
  `}

  ${media.lg`
    margin-top: ${props => props.mt[3]};
    margin-bottom: ${props => props.mb[3]};
    text-align: ${props => props.alignment[3]};
  `}

  a {
    color: inherit;
    text-decoration: none;
  }
`;
