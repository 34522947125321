import styled from 'styled-components';

import { scale, media } from '@/styles/utils';

export default styled.div.attrs(({ mb, mt, theme }) => ({
  mt: mt ? mt.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  mb: mb ? mb.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  maxWidth: scale(theme.grid.maxWidth + theme.grid.outerMargin[3]),
  outerMargin: theme.grid.outerMargin.map(m => (m ? scale(m) : 0)),
}))`
  display: block;
  position: relative;
  margin-top: ${props => props.mt[0]};
  margin-bottom: ${props => props.mb[0]};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${props => props.outerMargin[0]};
  padding-right: ${props => props.outerMargin[0]};
  width: 100%;
  max-width: ${props => props.maxWidth};

  ${media.sm`
    margin-top: ${props => props.mt[1]};
    margin-bottom: ${props => props.mb[1]};
    padding-left: ${props => props.outerMargin[1]};
    padding-right: ${props => props.outerMargin[1]};
  `}

  ${media.md`
    margin-top: ${props => props.mt[2]};
    margin-bottom: ${props => props.mb[2]};
    padding-left: ${props => props.outerMargin[2]};
    padding-right: ${props => props.outerMargin[2]};
  `}

  ${media.lg`
    margin-top: ${props => props.mt[3]};
    margin-bottom: ${props => props.mb[3]};
    padding-left: ${props => props.outerMargin[3]};
    padding-right: ${props => props.outerMargin[3]};
  `}
`;
