import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import BackgroundImage from './BackgroundImage';

import { media, scale } from '@/styles/utils';

const Container = styled.section`
  position: relative;
  overflow: hidden;

  /* compensate for section paddings */
  margin-top: -${scale(4)};
  margin-bottom: -${scale(3.5)};

  ${media.md`
    margin-top: -${scale(6)};
    margin-bottom: -${scale(5)};
  `}
`;

const Content = styled(Grid.Container)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export default ({
  headline,
  backgroundImageVertical,
  backgroundImageHorizontal,
  ...props
}) => (
  <Container {...props}>
    <BackgroundImage
      verticalImage={backgroundImageVertical[0]}
      horizontalImage={backgroundImageHorizontal[0]}
    />

    <Content>
      <Grid>
        <Grid.Item w={[1, 1 / 2]} mt={[2.5, 2.5, 3, 4.5]}>
          <Heading h="hero" as="h1" alignment={['left']} inverted>
            {headline}
          </Heading>
        </Grid.Item>
      </Grid>
    </Content>
  </Container>
);

export const query = graphql`
  fragment contentModuleHeroData on CraftCMS_ModulesHero {
    id
    headline
    backgroundImageVertical {
      ... on CraftCMS_ImagesVolume {
        filename
        width
        height
        title
      }
    }
    backgroundImageHorizontal {
      ... on CraftCMS_ImagesVolume {
        filename
        width
        height
        title
      }
    }
  }
`;
