import React from 'react';
import styled from 'styled-components';

const Svg = styled.svg`
  display: block;
`;

export default props => (
  <Svg
    width="51"
    height="17"
    viewBox="0 0 51 17"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>Knapp AG</title>

    <path
      d="M0 2.532h50.995V0H0v2.532zM.006 17H51v-2.532H.006V17zm25.55-11.033c.656 1.188 2.796 5.065 3.45 6.253h-6.901c.658-1.188 2.797-5.065 3.451-6.253zm-.465-1.407l-4.849 8.785h10.63l-5.316-9.63-.465.845zm20.07 3.697h2.39c.79 0 1.352-.153 1.683-.461.333-.308.497-.74.497-1.3 0-.403-.098-.75-.294-1.036a1.375 1.375 0 0 0-.77-.574c-.207-.055-.586-.084-1.141-.084H45.16v3.455zm-1.222 5.071V3.66h3.486c.612 0 1.08.032 1.405.092.454.08.833.23 1.142.456.306.22.552.53.737.93.19.4.283.84.283 1.318 0 .823-.25 1.52-.75 2.092-.5.567-1.404.85-2.71.85H45.16v3.93h-1.22zm-9.173-5.071h2.39c.79 0 1.35-.153 1.683-.461.332-.308.497-.74.497-1.3 0-.403-.098-.75-.296-1.036a1.361 1.361 0 0 0-.768-.574c-.208-.055-.587-.084-1.142-.084h-2.364v3.455zm-1.223 5.071V3.66h3.487c.613 0 1.082.032 1.406.092.453.08.833.23 1.14.456.307.22.552.53.738.93.188.4.283.84.283 1.318 0 .823-.25 1.52-.75 2.092-.5.567-1.403.85-2.71.85h-2.371v3.93h-1.223zm-23.057 0V3.66h1.255l4.854 7.592V3.66h1.172v9.668h-1.254l-4.856-7.597v7.597h-1.17zm-10.435 0V3.66h1.224v4.795l4.59-4.795h1.657L3.646 7.579l4.047 5.75H6.079L2.79 8.434 1.275 9.978v3.35H.051z"
      fill="inherit"
      fillRule="evenodd"
    />
  </Svg>
);
