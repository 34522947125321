/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}
export const onClientEntry = () => {
  window.onload = () => {
  addScript("https://www.eye-able-cdn.com/configs/www.apostore.com.js")
  addScript("https://www.eye-able-cdn.com/public/js/eyeAble.js")
  }
}
/*

<script async type="text/javascript" src="https://www.eye-able-cdn.com/configs/www.apostore.com.js"></script>
<script async type="text/javascript" src="https://www.eye-able-cdn.com/public/js/eyeAble.js"></script>
*/
