import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';
import TextButton from '@/atoms/TextButton';

import { scale } from '@/styles/utils';

const ButtonWrapper = styled.div`
  padding-top: ${scale(0.5)};
`;

const Footer = styled.header`
  margin-top: ${scale(0.75)};

  ${props =>
    props.spacing === 'large' &&
    `
    margin-top: ${scale(1.25)};
  `}

  ${ButtonWrapper} {
    ${props =>
      props.spacing === 'large' &&
      `
      padding-top: ${scale(1.25)};
    `}
  }
`;

export default ({ text, hasButton, buttonLabel, spacing, link, ...props }) => (
  <Footer spacing={spacing} {...props}>
    {text && (
      <Text t={-2} textColor="grayMedium">
        {text}
      </Text>
    )}

    {hasButton && (
      <ButtonWrapper>
        <TextButton to={link}>{buttonLabel}</TextButton>
      </ButtonWrapper>
    )}
  </Footer>
);
