import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import Button from '@/atoms/Button';

import { scale } from '@/styles/utils';

const Contact = styled.dl`
  margin-bottom: ${scale(1.5)};
`;

const ContactLabel = styled(Heading).attrs({ h: 6, as: 'dt' })`
  display: inline-block;
  width: ${scale(4)};
`;

const ContactText = styled(Text).attrs({ t: -1, as: 'dd' })`
  display: inline-block;
  padding-bottom: ${scale(0.25)};
  width: calc(100% - ${scale(4)});
`;

export default ({
  address,
  contactPerson,
  mobile,
  mobile2,
  mobile3,
  phone,
  phone2,
  phone3,
  fax,
  email,
  email2,
  email3,
  website,
}) => (
  <>
    {contactPerson && (
      <Text mb={[1]} t={-1}>
        {contactPerson}
      </Text>
    )}

    {address && (
      <Text mb={[1, 1, 1.25]} t={-1}>
        {address}
      </Text>
    )}

    <Contact>
      {mobile && (
        <>
          <ContactLabel>
            <Trans id="contact.mobileNumber">Mobil</Trans>
          </ContactLabel>
          <ContactText>{mobile}</ContactText>
        </>
      )}

      {mobile2 && (
        <>
          <ContactLabel>
            <Trans id="contact.mobileNumber">Mobil</Trans>
          </ContactLabel>
          <ContactText>{mobile2}</ContactText>
        </>
      )}

      {mobile3 && (
        <>
          <ContactLabel>
            <Trans id="contact.mobileNumber">Mobil</Trans>
          </ContactLabel>
          <ContactText>{mobile3}</ContactText>
        </>
      )}

      {phone && (
        <>
          <ContactLabel>
            <Trans id="contact.landline">Tel.</Trans>
          </ContactLabel>
          <ContactText>{phone}</ContactText>
        </>
      )}

      {phone2 && (
        <>
          <ContactLabel>
            <Trans id="contact.landline">Tel.</Trans>
          </ContactLabel>
          <ContactText>{phone2}</ContactText>
        </>
      )}

      {phone3 && (
        <>
          <ContactLabel>
            <Trans id="contact.landline">Tel.</Trans>
          </ContactLabel>
          <ContactText>{phone3}</ContactText>
        </>
      )}

      {fax && (
        <>
          <ContactLabel>
            <Trans id="contact.fax">Fax</Trans>
          </ContactLabel>
          <ContactText>{fax}</ContactText>
        </>
      )}

      {email && (
        <>
          <ContactLabel>
            <Trans id="contact.email">Email</Trans>
          </ContactLabel>
          <ContactText withUnderlinedLinks>
            <a href={`mailto:${email}`}>{email}</a>
          </ContactText>
        </>
      )}

      {email2 && (
        <>
          <ContactLabel>
            <Trans id="contact.email">Email</Trans>
          </ContactLabel>
          <ContactText withUnderlinedLinks>
            <a href={`mailto:${email2}`}>{email2}</a>
          </ContactText>
        </>
      )}

      {email3 && (
        <>
          <ContactLabel>
            <Trans id="contact.email">Email</Trans>
          </ContactLabel>
          <ContactText withUnderlinedLinks>
            <a href={`mailto:${email3}`}>{email3}</a>
          </ContactText>
        </>
      )}
    </Contact>

    {website && (
      <Button
        href={website}
        target="_blank"
        appearance="secondary"
        size="small"
      >
        <Trans id="contact.button.visitWebsite">Zur Webseite</Trans>
      </Button>
    )}
  </>
);
