import styled from 'styled-components';

import { media, scale } from '@/styles/utils';
import { p, pSmall, pxSmall, pxxSmall, pLarge } from '@/styles/typography';

export default styled.p.attrs(({ t, mt, mb, textColor, alignment }) => ({
  t: t || 0,
  mt: mt ? mt.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  mb: mb ? mb.map(m => (m ? `${scale(m)}` : '0')) : ['0', '0', '0', '0'],
  textColor: textColor || 'base',
  alignment: alignment || ['left', 'left', 'left', 'left'],
}))`
  ${props => props.t === -3 && pxxSmall}
  ${props => props.t === -2 && pxSmall}
  ${props => props.t === -1 && pSmall}
  ${props => props.t === 0 && p}
  ${props => props.t === 1 && pLarge}

  color: ${props => props.theme.color[props.textColor]};
  hyphens: auto;
  white-space: pre-line;
  margin-top: ${props => props.mt[0]};
  margin-bottom: ${props => props.mb[0]};
  text-align: ${props => props.alignment[0]};

  ${media.sm`
    margin-top: ${props => props.mt[1]};
    margin-bottom: ${props => props.mb[1]};
    text-align: ${props => props.alignment[1]};
  `}

  ${media.md`
    margin-top: ${props => props.mt[2]};
    margin-bottom: ${props => props.mb[2]};
    text-align: ${props => props.alignment[2]};
  `}

  ${media.lg`
    margin-top: ${props => props.mt[3]};
    margin-bottom: ${props => props.mb[3]};
    text-align: ${props => props.alignment[3]};
  `}

  strong {
    font-weight: inherit;
    color: ${props => props.theme.color.base};
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: all 0.1s ease-out;

    ${props =>
      props.withUnderlinedLinks &&
      `
      border-bottom: 1px solid ${props.theme.color[props.textColor]};

      :hover, :focus {
        color: ${props.theme.color.red};
        border-color: ${props.theme.color.red};
      }
    `}
  }
`;
