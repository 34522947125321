import React from 'react';
import styled from 'styled-components';
import { Field } from 'formik';

import { media, scale } from '@/styles/utils';
import { pLarge } from '@/styles/typography';

export default styled(({ hasError, isEmpty, isSubmitting, ...rest }) => (
  <Field rows="4" {...rest} />
))`
  border: 1px solid
    ${props =>
      props.hasError
        ? `${props.theme.color.red}`
        : `${props.theme.color.grayMedium}`};
  border-radius: ${props => scale(props.theme.measure.borderRadius.base)};
  padding: ${scale(0.375)} ${scale(0.5)};
  width: 100%;
  ${pLarge}
  background: transparent;
  -webkit-appearance: none;
  color: ${props =>
    props.isSubmitting
      ? props.theme.color.grayDark
      : props.theme.color.grayDarker};
  resize: vertical;
  outline: none;

  ${media.md`
    padding: ${scale(0.75)} ${scale(1)};
  `}

  :focus {
    ${props =>
      !props.hasError &&
      `
      border-color: ${props.theme.color.grayDarker};
    `};
  }

  ::placeholder {
    color: ${props => props.theme.color.grayDark};
    opacity: 1;
  }

  :disabled {
    color: ${props => props.theme.color.grayDark};
    -webkit-text-fill-color: ${props => props.theme.color.grayDark};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: ${props =>
      props.isSubmitting
        ? props.theme.color.grayDark
        : props.theme.color.grayDarker};
  }
`;
