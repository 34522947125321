import { useEffect } from 'react';
import { useSpring } from 'react-spring';

import theme from '@/styles/theme';

const isBrowser = typeof window !== 'undefined';

const breakpointMD = parseInt(theme.breakpoints[2].replace('em', ''), 10) * 16;

const mainBarHeightXS = theme.measure.header.mainBar[0] * 16;
const mainBarHeightMD = theme.measure.header.mainBar[1] * 16;
const secondaryBarHeightXS = theme.measure.header.secondaryBar[0] * 16;
const secondaryBarHeightMD = theme.measure.header.secondaryBar[1] * 16;

const getScrollTargetY = element => {
  const elementOffset = element.offsetTop;

  const isScrollingUp = elementOffset < window.scrollY;

  // depending on scroll-direction the delta takes the full header or only the sub-naivgation into account (because of headroom)
  let delta = 0;

  if (window.innerWidth < breakpointMD) {
    delta = isScrollingUp
      ? mainBarHeightXS + secondaryBarHeightXS
      : secondaryBarHeightXS;
  } else {
    delta = isScrollingUp
      ? mainBarHeightMD + secondaryBarHeightMD
      : secondaryBarHeightMD;
  }

  // +1 makes the animation seem smoother
  return elementOffset - delta + 1;
};

export default () => {
  // fallback for SSR
  if (!isBrowser) {
    return [() => {}];
  }

  // initialization (no animation happens)
  const [, setScrollAnimation, stopScrollAnimation] = useSpring(() => ({
    to: { y: 0 },
    onFrame: ({ y }) => {
      window.scroll(0, y);
    },
  }));

  // stop animation on user intervention
  useEffect(() => {
    window.addEventListener('wheel', stopScrollAnimation);
    window.addEventListener('touchstart', stopScrollAnimation);

    return () => {
      window.removeEventListener('wheel', stopScrollAnimation);
      window.removeEventListener('touchstart', stopScrollAnimation);
    };
  }, []);

  // target can be number or ref
  return [
    target => {
      const scrollTargetY =
        typeof target === 'number' ? target : getScrollTargetY(target);

      // scroll from current scroll position
      setScrollAnimation({
        from: { y: window.scrollY },
        to: { y: scrollTargetY },
        reset: true,
      });

      return null;
    },
  ];
};
