import React from 'react';
import styled from 'styled-components';

import Grid from '@/atoms/Grid';
import Icon from '@/atoms/Icon';

import { media, scale } from '@/styles/utils';

const Container = styled(Grid.Container)`
  position: relative;
  top: -${scale(4)};
  height: 0;

  ${media.belowlg`
    display: none;
  `}
`;

const ArrowButton = styled.button`
  display: inline-block;
  border: 0;
  width: ${scale(2.5)};
  height: ${scale(2.5)};
  color: ${props =>
    props.isActive
      ? props.theme.color.grayMedium
      : props.theme.color.grayLight};
  background: transparent;
  cursor: ${props => (props.isActive ? 'pointer' : 'default')};
  outline: none;
  transition: all 0.1s ease-out;

  ${props =>
    props.isActive &&
    `
    :hover {
      color: ${props.theme.color.grayDark};
    }
  `}
`;

export default ({
  numSlides,
  activeSlide,
  handleNext,
  handlePrev,
  ...props
}) => (
  <Container {...props}>
    <Grid>
      <Grid.Item w={[5 / 12]} o={[0, 0, 7 / 12]}>
        <ArrowButton
          isActive={activeSlide !== 0}
          onClick={() => activeSlide !== 0 && handlePrev()}
        >
          <Icon size="large" type="caretLeft" />
        </ArrowButton>

        <ArrowButton
          isActive={activeSlide !== numSlides - 1}
          onClick={() => activeSlide !== numSlides - 1 && handleNext()}
        >
          <Icon size="large" type="caretRight" />
        </ArrowButton>
      </Grid.Item>
    </Grid>
  </Container>
);
