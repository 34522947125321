import React from 'react';
import styled from 'styled-components';

import { scale } from '@/styles/utils';

const Container = styled.div`
  z-index: 10;
  position: absolute;
  bottom: ${props => (props.withCaption ? scale(2.25) : scale(0.75))};
  left: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
`;

const Dot = styled.button`
  display: inline-block;
  margin: 0 ${scale(0.175)};
  border: 0;
  width: ${scale(0.5)};
  height: ${scale(0.5)};
  border-radius: 50%;
  background: black;
  opacity: ${props => (props.isActive ? 0.8 : 0.3)};
  pointer-events: all;
  cursor: pointer;
  outline: none;
  transition: opacity 0.1s ease-out;

  :hover {
    opacity: 0.5;
  }
`;

export default ({
  numSlides,
  activeSlide,
  handleSet,
  withCaption,
  ...props
}) => (
  <Container withCaption={withCaption} {...props}>
    {Array(numSlides)
      .fill(1)
      .map((a, i) => (
        /* eslint-disable react/no-array-index-key */
        <Dot
          key={i}
          isActive={i === activeSlide}
          onClick={() => handleSet(i)}
        />
        /* eslint-enable react/no-array-index-key */
      ))}
  </Container>
);
