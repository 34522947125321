import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Accordion from '@/components/Accordion';
import Table from '@/components/Table';

export default ({ sheet, ...props }) => (
  <Grid.Container mb={[1.5, 1.5, 2.5]} {...props}>
    <Grid>
      <Grid.Item w={[1, 1, 8 / 12]} o={[0, 0, 2 / 12]}>
        {sheet.length > 0 && (
          <Accordion
            firstSectionExpanded
            sections={sheet[0].sections.map(({ id, heading, data }) => ({
              id,
              title: heading,
              content: (
                <Table>
                  {data.map(({ title, content }) => (
                    <Table.Row key={title}>
                      <Table.Heading w={1 / 3}>{title}</Table.Heading>
                      <Table.Content w={2 / 3}>{content}</Table.Content>
                    </Table.Row>
                  ))}
                </Table>
              ),
            }))}
          />
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleDataSheetData on CraftCMS_ModulesDataSheet {
    id
    sheet {
      ... on CraftCMS_DataSheet {
        sections {
          id
          heading
          data {
            title
            content
          }
        }
      }
    }
  }
`;
