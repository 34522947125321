import React from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/macro';

import Button from '@/atoms/Button';
import Spinner from '@/atoms/Spinner';
import Icon from '@/atoms/Icon';

import { scale } from '@/styles/utils';

const getTranslateY = state => {
  switch (state) {
    case 'sending':
      return -3;
    case 'sent':
      return -6;
    default:
      return 0;
  }
};

const Wrapper = styled.span`
  display: block;
  transition: 0.15s ease-in-out;
`;

const SubmitButton = styled(Button).attrs(({ state }) => ({
  type: 'submit',
  disabled: ['sending', 'sent'].includes(state),
}))`
  overflow: hidden;
  align-items: flex-start;

  ${Wrapper} {
    transform: translateY(${props => scale(getTranslateY(props.state))});
  }

  ::after {
    opacity: ${props => (props.disabled ? 1 : 0)};
  }
`;

const ButtonIcon = styled(Icon)`
  margin-left: -${scale(0.5)};
  margin-right: ${scale(0.25)};
`;

const ButtonState = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${scale(3)};

  ${props =>
    props.isButtonLabel &&
    `
    padding-bottom: ${scale(0.2)};
  `}
`;

export default ({ submitState, ...props }) => {
  return (
    <SubmitButton state={submitState} {...props}>
      <Wrapper>
        <ButtonState isButtonLabel>
          <Trans id="submitButton.send">Absenden</Trans>
        </ButtonState>
        <ButtonState>
          <Spinner />
        </ButtonState>
        <ButtonState isButtonLabel>
          <ButtonIcon type="check" />
          <Trans id="submitButton.sent">Gesendet</Trans>
        </ButtonState>
      </Wrapper>
    </SubmitButton>
  );
};
