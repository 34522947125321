import React from 'react';
import styled from 'styled-components';
import Imgix, { buildURL } from 'react-imgix';

import { getAssetUrl } from '@/utils/routes';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  /* eslint-disable global-require */
  require('lazysizes');
  require('lazysizes/plugins/attrchange/ls.attrchange');
  /* eslint-enable global-require */
}

const Placeholder = styled.img`
  width: 100%;
  height: auto;
  filter: blur(10px);
  opacity: 1;
  transition: opacity 0.3s ease-out;
`;

const Picture = styled.picture`
  position: relative;
  display: block;
  overflow: hidden;

  &.lazyloaded ${Placeholder} {
    opacity: 0;
  }

  .lazyload,
  .lazyloading {
    opacity: 0;
  }

  .lazyloaded {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
`;

const Image = styled(Imgix)`
  ${props =>
    props.isBrowser
      ? `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  `
      : `
    width: 100%;
    height: auto;
  `}
`;

export default ({
  file,
  width,
  height,
  sizes,
  aspectRatio = null,
  imgixParams = {},
  alt = '',
  ...props
}) => {
  const src = getAssetUrl(file);
  const hasAspectRatio = Array.isArray(aspectRatio) && aspectRatio.length === 2;

  const imgixParamsWithAspectRatio = hasAspectRatio
    ? {
        ar: aspectRatio.join(':'),
        ...imgixParams,
      }
    : imgixParams;

  const baseImageProps = {
    src,
    sizes,
    imgixParams: {
      ...imgixParamsWithAspectRatio,
      auto: 'format',
      q: 80,
    },
    htmlAttributes: {
      alt,
    },
  };

  const placeholderProps = hasAspectRatio
    ? {
        width: 50,
        height: Math.round((50 * aspectRatio[1]) / aspectRatio[0]),
        fit: 'crop',
      }
    : { width: 50 };

  const imageProps = isBrowser
    ? {
        ...baseImageProps,
        className: 'lazyload',
        attributeConfig: {
          src: 'data-src',
          srcSet: 'data-srcset',
          sizes: 'data-sizes',
        },
      }
    : {
        ...baseImageProps,
        className: 'lazyloaded',
      };

  return (
    <Picture className={isBrowser ? 'lazyloaded' : 'lazyload'} {...props}>
      {isBrowser && (
        <Placeholder
          src={buildURL(src, placeholderProps)}
          width={hasAspectRatio ? aspectRatio[0] : width}
          height={hasAspectRatio ? aspectRatio[1] : height}
        />
      )}

      <Image isBrowser={isBrowser} {...imageProps} />
    </Picture>
  );
};
