import React, { useState } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import YouTube from '@u-wave/react-youtube';

import Grid from '@/atoms/Grid';
import Poster from './Poster';

const Player = styled.div`
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
`;

const Video = styled(YouTube)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const extractVideoId = url => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);

  if (match && match[7].length === 11) {
    return match[7];
  }

  return null;
};

const playerWidth = [1, 1, 10 / 12, 8 / 12];

export default ({ id, videoUrl, placeholderImage, ...props }) => {
  const [hasStarted, setStarted] = useState(false);
  const [player, setPlayer] = useState(null);
  const videoId = extractVideoId(videoUrl);

  return (
    <Grid.Container mb={[1.5, 1.5, 2.5]} {...props}>
      <Grid>
        <Grid.Item w={playerWidth} o={[0, 0, 1 / 12, 2 / 12]}>
          {videoId && (
            <div data-name="youtube">
            <Player>
              <Video
                video={videoId}
                annotations={false}
                modestBranding
                showRelatedVideos={false}
                showInfo={false}
                onReady={e => {
                  setPlayer(e.target);
                }}
              />

              <Poster
                onClick={() => {
                  player && player.playVideo();
                  setStarted(true);
                }}
                isVisible={!hasStarted}
                image={placeholderImage[0]}
                imageWidth={playerWidth}
              />
            </Player>
            </div>
          )}
        </Grid.Item>
      </Grid>
    </Grid.Container>
  );
};

export const query = graphql`
  fragment contentModuleVideoData on CraftCMS_ModulesVideo {
    id
    videoUrl
    placeholderImage {
      filename
      width
      height
      title
    }
  }
`;
