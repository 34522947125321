import React, { useContext } from 'react';
import { Trans, t } from '@lingui/macro';

import { ContactProvider } from '@/context/ContactContext';
import LocalizationContext from '@/context/LocalizationContext';
import { localesData } from '@/utils/localization';

import Head from '@/components/Head';
import PageContent from '@/components/PageContent';

export default () => {
  const [locale, i18n] = useContext(LocalizationContext);

  return (
    <ContactProvider>
      <Head title={i18n._(t('404.title')`Seite nicht gefunden`)} noIndex />

      <PageContent
        showSubnavigation={false}
        showContactButton={false}
        contactButtonLabel={false}
        sections={[
          {
            modules: [
              {
                id: '404_1',
                headline: (
                  <Trans id="404.headline">
                    Die Seite wurde leider nicht gefunden
                  </Trans>
                ),
                text: (
                  <Trans id="404.text">
                    Die von Ihnen aufgerufene URL existiert leider nicht oder
                    ist veraltet. Bitte verwenden Sie die Navigation um zum
                    gewünschten Inhalt zu gelangen oder besuchen Sie unsere
                    Startseite.
                  </Trans>
                ),
                type: 'CraftCMS_ModulesIntro',
              },
              {
                id: '404_2',
                type: 'CraftCMS_ModulesButton',
                appearance: 'primary',
                label: <Trans id="404.button.toHome">Zur Startseite</Trans>,
                target: [{ uri: localesData[locale].baseUrl }],
              },
            ],
          },
        ]}
      />
    </ContactProvider>
  );
};
