import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from '@/atoms/Icon';

import { scale } from '@/styles/utils';
import { buttonLabel } from '@/styles/typography';

const LeftIcon = styled(Icon)`
  margin-right: ${scale(0.25)};
`;

const StyledButton = styled.button`
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: transparent;
  overflow: hidden;
  outline: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  transition: all 0.1s ease-out;
  ${buttonLabel}

  ${props =>
    props.size === 'medium' &&
    `
    padding: 0 ${scale(2.25)} ${scale(0.2)};
    height: ${scale(3)};
    border-radius: ${scale(1.5)};
  `}

  ${props =>
    props.size === 'small' &&
    `
    padding: 0 ${scale(1.5)} ${scale(0.1)};
    height: ${scale(2.5)};
    border-radius: ${scale(1.25)};
  `}

  ${props =>
    props.appearance === 'primary' &&
    `
    color: white;
    background: ${props.theme.gradient.red};

    ::before, ::after {
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      content: "";
      display: block;
      background: ${props.theme.gradient.redDark};
      opacity: 0;
      transition: opacity 0.1s ease-out;
    }

    ::after {
      background: ${props.theme.gradient.redDarker};
    }

    :hover, :focus {
      ::before {
        opacity: 1;
      }
    }

    :active {
      ::after {
        opacity: 1;
      }
    }
  `}

  ${props =>
    props.appearance === 'secondary' &&
    `
    border: 1px solid ${props.theme.color.red};
    color: ${props.theme.color.red};

    :hover, :focus {
      border-color: ${props.theme.color.redDark};
      color: ${props.theme.color.redDark};
    }

    :active {
      border-color: ${props.theme.color.redDarker};
      color: ${props.theme.color.redDarker};
    }
  `}

  ${props =>
    props.appearance === 'secondaryInverted' &&
    `
    border: 1px solid ${props.theme.color.grayLighter};
    color: ${props.theme.color.grayLighter};

    :hover, :focus {
      border-color: ${props.theme.color.grayMediumLight};
      color: ${props.theme.color.grayMediumLight};
    }

    :active {
      border-color: ${props.theme.color.grayMedium};
      color: ${props.theme.color.grayMedium};
    }
  `}
`;

const Button = ({
  to = null,
  href = null,
  appearance = 'primary',
  icon = null,
  size = 'medium',
  disabled = false,
  children,
  ...props
}) => {
  const buttonProps = {
    appearance,
    disabled,
    size,
  };

  if (to) {
    if (!disabled) {
      buttonProps.to = to;
      buttonProps.as = Link;
    } else {
      buttonProps.as = 'div';
    }
  }

  if (href) {
    if (!disabled) {
      buttonProps.href = href;
      buttonProps.as = 'a';
    } else {
      buttonProps.as = 'div';
    }
  }

  return (
    <StyledButton {...buttonProps} {...props}>
      {icon && <LeftIcon type={icon} />}
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  appearance: propTypes.oneOf(['primary', 'secondary', 'secondaryInverted']),
  size: propTypes.oneOf(['medium', 'small']),
};

Button.defaultProps = {
  appearance: 'primary',
  size: 'medium',
};

export default Button;
