export default {
  breakpoints: ['40em', '58em', '75em'],
  scale: 1, // (in rem) => scale(1) = 16px

  grid: {
    // per breakpoint, in scale
    gutter: [1, 1, 1.5, 1.5],
    outerMargin: [1, 1, 1.5, 1.5],
    maxWidth: 81,
  },

  color: {
    bg: '#fff',
    base: '#5e717d', // = grayDark
    red: '#ca1723',
    redDark: '#b1000a',
    redDarker: '#970000',
    grayLighter: '#f8f9fb',
    grayLight: '#e2e7eb',
    grayMediumLight: '#c7d0d8',
    grayMedium: '#93a1ad',
    grayDark: '#5e717d',
    grayDarker: '#2a353e',
    grayDarker10: '#29333c',
  },

  gradient: {
    red: 'linear-gradient( to right bottom, #ca1723, #9b111a)',
    redDark: 'linear-gradient( to right bottom, #b1000a, #820001)',
    redDarker: 'linear-gradient( to right bottom, #970000, #680000)',
    grayLighter: 'linear-gradient( to right bottom, #fbfcff, #f8f9fb)',
    grayMedium: 'linear-gradient( to right bottom, #93a1ad, #86939e)',
    grayDarker: 'linear-gradient( to right bottom, #2a353e, #212931)',
  },

  font: {
    sans: 'Graphik, sans-serif',
  },

  measure: {
    header: {
      mainBar: [4, 7.5],
      secondaryBar: [4, 4.5],
    },
    borderRadius: {
      base: 0.25,
    },
  },
};
