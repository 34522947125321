import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import FormattedText from '@/atoms/FormattedText';

export default ({ headline, subheading, text, ...props }) => (
  <Grid.Container {...props}>
    <Grid>
      <Grid.Item
        w={[1, 1, 10 / 12, 8 / 12]}
        o={[0, 0, 1 / 12, 2 / 12]}
        mb={[1.5, 1.5, 2.5]}
      >
        <Heading
          h={1}
          as="h1"
          alignment={['left', 'left', 'center']}
          mb={subheading ? [0.5, 0.5, 0.75] : [1.5, 1.5, 2.5]}
        >
          {headline}
        </Heading>

        {subheading && (
          <Heading
            h={2}
            as="h2"
            alignment={['left', 'left', 'center']}
            mb={[1.5, 1.5, 2.5]}
            textColor="grayMedium"
          >
            {subheading}
          </Heading>
        )}

        {text && (
          <FormattedText t={1} alignment={['left', 'left', 'center']}>
            {text}
          </FormattedText>
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleIntroData on CraftCMS_ModulesIntro {
    id
    headline
    subheading
    text
  }
`;
