import React from 'react';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';
import Image from '@/atoms/Image';

import { getSrcsetSizesFromGridWidths } from '@/utils/grid';

import ProductData from './ProductData';

const imageWidth = [1, 1, 1 / 2, 7 / 12];
const contentWidth = [1, 1, 1 / 2, 5 / 12];

export default ({
  extensionTitle,
  pageTitle,
  image,
  capacity,
  dispensing,
  description,
  ...props
}) => (
  <Grid.Container {...props}>
    <Grid alignItems="center">
      <Grid.Item w={imageWidth}>
        <Image
          file={image[0].filename}
          width={image[0].width}
          height={image[0].height}
          alt={image[0].title}
          sizes={getSrcsetSizesFromGridWidths(imageWidth)}
        />
      </Grid.Item>

      <Grid.Item w={contentWidth}>
        <Heading h={1} as="h1" mt={[1.5]} mb={[1]} alignment={['center']}>
          {pageTitle}
        </Heading>

        {description && (
          <Text t={1} alignment={['center']} mb={[3]}>
            {description}
          </Text>
        )}


        {(capacity || dispensing) && (
          <ProductData capacity={capacity} dispensing={dispensing} />
        )}
      </Grid.Item>
    </Grid>
  </Grid.Container>
);

export const query = graphql`
  fragment contentModuleProductIntroData on CraftCMS_ModulesProductIntro {
    id
    image {
      filename
      width
      height
      title
    }
    description
  }

  fragment pageProductExtensionData on CraftCMS_PageProductExtension {
    id
    title
  }

  fragment pageProductAutomatData on CraftCMS_PageProductAutomat {
    id
    title
    capacity
    dispensing
  }
`;
