import React from 'react';
import styled from 'styled-components';
import Imgix, { Source } from 'react-imgix';

import { getAssetUrl } from '@/utils/routes';
import theme from '@/styles/theme';

const breakpointSM =
  parseInt(theme.breakpoints[0].replace('em', ''), 10) * 16 - 1;

const Picture = styled.picture`
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export default ({ verticalImage, horizontalImage, ...props }) => {
  const srcVertical = getAssetUrl(verticalImage.filename);
  const srcHorizontal = getAssetUrl(horizontalImage.filename);

  const imageProps = {
    imgixParams: {
      auto: 'format',
      q: 80,
    },
  };

  return (
    <Picture {...props}>
      <Source
        src={srcVertical}
        width={breakpointSM}
        htmlAttributes={{ media: `(max-width: ${breakpointSM}px)` }}
        {...imageProps}
      />

      <Imgix
        src={srcHorizontal}
        htmlAttributes={{ alt: horizontalImage.title }}
        sizes="100vw"
      />
    </Picture>
  );
};
