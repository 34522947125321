import GraphikLightWoff1 from '@/assets/fonts/Graphik-Light-Web.woff';
import GraphikLightWoff2 from '@/assets/fonts/Graphik-Light-Web.woff2';
import GraphikRegularWoff1 from '@/assets/fonts/Graphik-Regular-Web.woff';
import GraphikRegularWoff2 from '@/assets/fonts/Graphik-Regular-Web.woff2';
import GraphikMediumWoff1 from '@/assets/fonts/Graphik-Medium-Web.woff';
import GraphikMediumWoff2 from '@/assets/fonts/Graphik-Medium-Web.woff2';

export default `
  @font-face {
    font-family: 'Graphik';
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    src: url(${GraphikLightWoff2}) format('woff2'),
      url(${GraphikLightWoff1}) format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    src: url(${GraphikRegularWoff2}) format('woff2'),
      url(${GraphikRegularWoff1}) format('woff');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    src: url(${GraphikMediumWoff2}) format('woff2'),
      url(${GraphikMediumWoff1}) format('woff');
  }
`;
