import React from 'react';
import styled from 'styled-components';
import { media } from '@/styles/utils';

import Text from '@/atoms/Text';

const Content = styled(Text).attrs({
  t: -1,
  as: 'td',
  textColor: 'grayDarker',
})`
  display: block;
  width: 100%;

  ${media.md`
    width: ${props => props.w * 100}%;
  `}
`;

export default ({ w = 1, children, ...props }) => (
  <Content w={w} {...props}>
    {children}
  </Content>
);
