import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';

import Grid from '@/atoms/Grid';
import Button from '@/atoms/Button';

import { getPath } from '@/utils/routes';

const ButtonContainer = styled(Grid.Container)`
  text-align: center;
`;

export default ({ appearance, label, target, ...props }) => (
  <ButtonContainer mb={[1.5, 1.5, 2.5]} {...props}>
    <Button to={target[0] && getPath(target[0].uri)} appearance={appearance}>
      {label}
    </Button>
  </ButtonContainer>
);

export const query = graphql`
  fragment contentModuleButtonData on CraftCMS_ModulesButton {
    id
    appearance
    label
    target {
      uri
    }
  }
`;
