import React from 'react';
import styled from 'styled-components';
import { Trans, NumberFormat } from '@lingui/macro';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

const Table = styled.div`
  display: block;
  padding-top: ${scale(1.5)};
  border-top: 1px solid ${props => props.theme.color.grayLight};
`;

const RowHeading = styled(Heading).attrs({
  h: 6,
  scope: 'row',
  alignment: ['center', 'center', 'center', 'center'],
})`
  display: block;
`;

const RowText = styled(Text).attrs({
  t: -1,
  alignment: ['center', 'center', 'center', 'center'],
})`
  display: block;
  color: ${props => props.theme.color.grayDarker};
`;

export default ({ capacity, dispensing, ...props }) => (
  <Table {...props}>
    <Grid>
      <Grid.Item w={[1, 1, 1 / 2]} mb={[1]}>
        <RowHeading>
          <Trans id="product.data.capacity.heading">Kapazität</Trans>
        </RowHeading>

        <RowText>
          <Trans id="product.data.capacity.value">
            bis zu <NumberFormat value={capacity} /> Packungen
          </Trans>
        </RowText>
      </Grid.Item>

      <Grid.Item w={[1, 1, 1 / 2]} mb={[1]}>
        <RowHeading>
          <Trans id="product.data.dispensing.heading">Ein-/Auslagerung</Trans>
        </RowHeading>

        <RowText>
          <Trans id="product.data.dispensing.value">
            bis zu
            <NumberFormat value={dispensing} /> Packungen/h
          </Trans>
        </RowText>
      </Grid.Item>
    </Grid>
  </Table>
);
