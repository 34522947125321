import React from 'react';
import styled from 'styled-components';

import { media, scale } from '@/styles/utils';

const Row = styled.tr`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${scale(1)};

  ${media.md`
    flex-wrap: nowrap;
    margin-bottom: ${scale(0.5)};
  `}
`;

export default ({ children, ...props }) => <Row {...props}>{children}</Row>;
