import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Gallery from '@/components/Gallery';
import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';
import Button from '@/atoms/Button';
import RichText from '@/atoms/RichText';

import { media, scale } from '@/styles/utils';
import { getPath } from '@/utils/routes';

const Container = styled(Grid.Container)`
  &&:last-child {
    margin-bottom: ${scale(1.5)};

    ${media.md`
      margin-bottom: ${scale(2.5)};
    `}
  }
`;

const ImageContainer = styled(Grid.Item)`
  && {
    margin-bottom: ${scale(1.5)};

    ${media.md`
      margin-bottom: 0;
    `}
  }

  ${media.md`
    ${props =>
      props.orientation === 'imageRight' &&
      `
      order: 2;
    `}
  `}
`;

const ButtonContainer = styled.div`
  text-align: center;

  ${media.md`
    text-align: left;
  `}
`;

export default ({
  headline,
  richText,
  images,
  orientation,
  buttonLabel,
  buttonTarget,
  buttonAppearance,
  ...props
}) => {
  const imageOffset = orientation === 'imageLeft' ? [0] : [0, 0, 0, 1 / 12];
  const textOffset = orientation === 'imageLeft' ? [0, 0, 0, 1 / 12] : [0];
  const imageWidth = [1, 1, 6 / 12];

  return (
    <Container mt={[0, 0, 1.5]} mb={[1.5, 1.5, 6]} {...props}>
      <Grid alignItems="center">
        <ImageContainer
          orientation={orientation}
          w={imageWidth}
          o={imageOffset}
        >
          {images.length !== 0 && (
            <Gallery images={images} sizes={imageWidth} />
          )}
        </ImageContainer>

        <Grid.Item w={[1, 1, 6 / 12, 5 / 12]} o={textOffset}>
          {headline && (
            <Heading h={2} as="h2" mb={[1.5, 1.5, 2]}>
              {headline}
            </Heading>
          )}

          {richText && (
            <RichText htmlContent={richText.content} mb={[2, 2, 2.5]} />
          )}

          {buttonLabel && (
            <ButtonContainer>
              <Button
                to={buttonTarget[0] && getPath(buttonTarget[0].uri)}
                appearance={buttonAppearance}
              >
                {buttonLabel}
              </Button>
            </ButtonContainer>
          )}
        </Grid.Item>
      </Grid>
    </Container>
  );
};

export const query = graphql`
  fragment contentModuleImagesTextData on CraftCMS_ModulesImagesText {
    id
    orientation
    headline
    richText {
      content
    }
    images {
      ... on CraftCMS_ImagesVolume {
        filename
        width
        height
        title
        caption
      }
    }
    buttonLabel
    buttonTarget {
      uri
    }
    buttonAppearance
  }
`;
