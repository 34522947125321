import React from 'react';
import styled from 'styled-components';

import Image from '@/atoms/Image';
import Text from '@/atoms/Text';

import { scale } from '@/styles/utils';

const Slide = styled.figure`
  z-index: ${props => (props.isActive ? 1 : 0)};
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: opacity 0.4s ease-out;

  &:first-child {
    position: static;
  }

  & > picture {
    ${props =>
      props.isCarousel &&
      `
      cursor: pointer;
    `}
  }
`;

const Caption = styled(Text).attrs({ t: -1, as: 'figcaption' })`
  padding-top: ${scale(0.5)};
  height: ${scale(1.5)};
  overflow: visible;
`;

export default ({
  image,
  sizes,
  isActive,
  handleClick,
  isCarousel,
  ...props
}) => (
  <Slide isActive={isActive} isCarousel={isCarousel} {...props}>
    <Image
      file={image.filename}
      width={image.width}
      height={image.height}
      alt={image.title}
      sizes={sizes}
      onClick={handleClick}
    />

    {image.caption && <Caption>{image.caption}</Caption>}
  </Slide>
);
