import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';

import { media, scale } from '@/styles/utils';

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${scale(1.5)};
  border-top: 1px solid ${props => props.theme.color.grayLight};
  padding-top: ${scale(1)};
  width: 100%;
  list-style: none;

  ${media.md`
    margin-top: ${scale(2.5)};
    border: 0;
    padding: 0;
  `}
`;

const NavItem = styled.li`
  display: block;
  margin: 0 ${scale(0.175)};

  ${media.md`
    margin: 0 ${scale(1)};
  `};
`;

const NavLink = styled(Text).attrs({
  as: 'button',
  t: -1,
})`
  border: 0;
  width: ${scale(0.5)};
  height: ${scale(0.5)};
  border-radius: 50%;
  color: ${props =>
    props.isActive
      ? props.theme.color.grayDarker
      : props.theme.color.grayMedium};
  background: black;
  opacity: ${props => (props.isActive ? 0.8 : 0.3)};
  cursor: pointer;
  text-indent: -9999px;
  overflow: hidden;
  outline: none;
  transition: all 0.1s ease-out;

  ${media.md`
    width: auto;
    height: auto;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    text-indent: 0;
  `}

  :hover {
    color: ${props =>
      props.isActive
        ? props.theme.color.grayDarker
        : props.theme.color.grayDark};
  }
`;

export default ({ items, activeSlide, handleSet, ...props }) => (
  <NavList {...props}>
    {items.map((item, i) => (
      <NavItem key={item}>
        <NavLink onClick={() => handleSet(i)} isActive={activeSlide === i}>
          {item}
        </NavLink>
      </NavItem>
    ))}
  </NavList>
);
