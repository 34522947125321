import React, { useContext } from 'react';
import styled from 'styled-components';
import { ErrorMessage } from 'formik';
import { t } from '@lingui/macro';

import LocalizationContext from '@/context/LocalizationContext';
import { scale } from '@/styles/utils';

import InputField from '@/atoms/InputField';
import TextArea from '@/atoms/TextArea';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';
import Grid from '@/atoms/Grid';

const Wrapper = styled(Grid.Item)`
  position: relative;
`;

const ErrorMessageContainer = styled(Text).attrs({ t: -3, textColor: 'red' })`
  position: absolute;
  bottom: -${scale(1.25)};
`;

const Label = styled(Heading).attrs({ h: 6, as: 'label' })`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s ease-out;
`;

export default ({
  label,
  name,
  placeholder,
  type = 'text',
  component,
  values,
  touched,
  errors,
  isOptional = false,
  width = 1,
  isSubmitting,
  ...props
}) => {
  const [, i18n] = useContext(LocalizationContext);

  const isEmpty = values[name].length < 1;

  const placholderText = placeholder || label;

  const fieldOptions = {
    id: name,
    name,
    placeholder: isOptional
      ? `${placholderText} ${i18n._(t('form.inputfield.optional')`(optional)`)}`
      : placholderText,
    type,
    label,
    component,
    isEmpty,
    hasError: touched[name] && errors[name],
    isSubmitting,
  };

  return (
    <Wrapper w={[1, 1, width]} mb={[1.5]} {...props}>
      <Label htmlFor={name} isVisible={!isEmpty}>
        {label}
      </Label>

      {component === 'textarea' ? (
        <TextArea placeholder={placholderText} {...fieldOptions} />
      ) : (
        <InputField {...fieldOptions} />
      )}

      <ErrorMessageContainer>
        <ErrorMessage name={name} />
      </ErrorMessageContainer>
    </Wrapper>
  );
};
