import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Trans } from '@lingui/macro';

import Text from '@/atoms/Text';
import LogoKnapp from '@/atoms/LogoKnapp';

import { scale, media } from '@/styles/utils';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.color.grayDark};
`;

const Links = styled.ul`
  padding-bottom: ${scale(0.5)};
  width: 100%;
  list-style: none;
  text-align: center;

  & > li {
    display: inline-block;
    padding-right: ${scale(1)};

    :last-child {
      padding-right: 0;
    }

    a {
      color: ${props => props.theme.color.grayDark};
      text-decoration: none;

      :hover,
      :focus {
        border-bottom: 1px solid ${props => props.theme.color.grayDark};
      }
    }
  }

  ${media.sm`
    order: 3;
    width: auto;
    flex-grow: 1;
    text-align: right;
  `}
`;

const Copyright = styled(Text).attrs({ t: -2 })`
  padding-bottom: ${scale(0.5)};
  width: 100%;
  text-align: center;

  ${media.sm`
    padding-right: ${scale(1)};
    width: auto;
    text-align: left;
  `}
`;

const Knapp = styled(Text).attrs({ t: -2 })`
  padding-bottom: ${scale(0.5)};
  width: 100%;
  text-align: center;

  ${media.sm`
    width: auto;
    text-align: left;
  `}

  :hover,
  :focus {
    span {
      border-bottom: 1px solid ${props => props.theme.color.grayDark};
    }
  }
`;

const KnappLogo = styled(LogoKnapp)`
  position: relative;
  top: ${scale(0.1)};
  display: inline-block;
  margin-left: ${scale(0.25)};
  vertical-align: top;
  fill: ${props => props.theme.color.grayDark};
`;

export default ({ navigationItems, ...props }) => {
  const today = new Date();

  return (
    <Container {...props}>
      <Links>
        {navigationItems.map(item => (
          <Text t={-2} as="li" key={item.id}>
            <Link to={item.path}>{item.label}</Link>
          </Text>
        ))}
      </Links>

      <Copyright>
        <Trans id="footer.legal.copyright">
          © {today.getFullYear()} KNAPP Smart Solutions GmbH
        </Trans>
      </Copyright>

      <Knapp>
        <a
          href="https://www.knapp.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <KnappLogo />
        </a>
      </Knapp>
    </Container>
  );
};

/*
<span>
  <Trans id="footer.knappGroup">A Member of Knapp Group</Trans>
</span>{' '}
*/
