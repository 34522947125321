import React from 'react';
import styled from 'styled-components';

import { media, scale } from '@/styles/utils';

import Text from '@/atoms/Text';

const Heading = styled(Text).attrs({
  t: -1,
  alignment: ['left'],
  as: 'th',
})`
  display: block;
  padding-bottom: ${scale(0.25)};
  width: 100%;

  ${media.md`
    padding-bottom: 0;
    width: ${props => props.w * 100}%;
  `}
`;

export default ({ w = 1, children, ...props }) => (
  <Heading scope="row" w={w} {...props}>
    {children}
  </Heading>
);
