import React from 'react';
import styled from 'styled-components';

import Text from '@/atoms/Text';

const Main = styled(Text).attrs({ t: -1 })`
  ${props =>
    props.grow &&
    `
    flex-grow: 1;
  `}
`;

export default ({ grow, children, ...props }) => (
  <Main grow={grow} {...props}>
    {children}
  </Main>
);
