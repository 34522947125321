import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { animated, useSpring } from 'react-spring';
import clamp from 'lodash-es/clamp';

import Grid from '@/atoms/Grid';
import SubNavigation from './SubNavigation';
import ContactButton from './ContactButton';

import { media, scale } from '@/styles/utils';

const SecondaryBar = styled.div`
  z-index: -100;
  position: absolute;
  left: 0;
  top: ${props => scale(props.theme.measure.header.mainBar[0])};
  border-bottom: 1px solid ${props => props.theme.color.grayLight};
  width: 100%;
  height: ${props => scale(props.theme.measure.header.secondaryBar[0])};
  background: ${props => props.theme.gradient.grayLighter};

  ${media.md`
    top: ${props => scale(props.theme.measure.header.mainBar[1])};
    height: ${props => scale(props.theme.measure.header.secondaryBar[1])};
  `}
`;

const SecondaryBarContainer = styled(animated(Grid.Container))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
`;

export default ({
  navigationItems,
  activeNavigationItem,
  showContactButton,
  onContactButtonClick,
  contactButtonLabel,
  ...props
}) => {
  const [scrollPos, setScroll] = useSpring(() => ({ x: 0 }));

  const secondaryBarContainerRef = useRef(null);
  const navItems = navigationItems.map(item => {
    return { itemRef: useRef(null), ...item };
  });

  useEffect(() => {
    const activeItem = navItems.filter(
      item => item.id === activeNavigationItem
    );

    const {
      offsetLeft: itemOffset,
      clientWidth: itemWidth,
    } = activeItem[0].itemRef.current;

    const {
      scrollWidth: contentWidth,
      clientWidth: containerWidth,
    } = secondaryBarContainerRef.current;

    const maxScrollPos = contentWidth - containerWidth;
    const targetScrollPos = Math.round(
      itemOffset - containerWidth / 2 + itemWidth / 2
    );

    setScroll({
      config: {
        mass: 1,
        tension: 190,
        friction: 26,
        clamp: true,
      },
      x: clamp(targetScrollPos, 0, maxScrollPos),
    });
  }, [activeNavigationItem]);

  return (
    <SecondaryBar {...props}>
      <SecondaryBarContainer
        ref={secondaryBarContainerRef}
        scrollLeft={scrollPos.x}
      >
        {showContactButton && (
          <ContactButton label={contactButtonLabel} isSpacer tabIndex="-1" />
        )}

        {navItems && (
          <SubNavigation
            navigationItems={navItems}
            activeItem={activeNavigationItem}
          />
        )}

        {showContactButton && (
          <ContactButton
            onClick={onContactButtonClick}
            label={contactButtonLabel}
          />
        )}
      </SecondaryBarContainer>
    </SecondaryBar>
  );
};
